import React from 'react';
import { SidebarLogoContainer } from './Sidebar.styled';
import { ReactComponent as Logo } from 'assets/mmi_logo.svg';
import { ReactComponent as FullLogo } from 'assets/mmi_text_logo.svg';

type Props = {
  collapsed: boolean;
};
const SidebarLogo: React.FC<Props> = ({ collapsed }) => {
  return <SidebarLogoContainer>{collapsed ? <Logo className="without-text" /> : <FullLogo />}</SidebarLogoContainer>;
};

export default SidebarLogo;
