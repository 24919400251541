import { Typography } from 'antd';
import { keywordPreviewParser } from 'shared/utils/keywordPreviewParser_v2';

const { Text } = Typography;
interface Props {
  keywords: any;
  additionalKeywords: any;
  additionalKeywords1?: any;
  additionalKeywords2?: any;
  additionalKeywords3?: any;
  excludedKeywords: any;
}
const KeywordsPreview = ({
  keywords,
  additionalKeywords,
  additionalKeywords1,
  additionalKeywords2,
  additionalKeywords3,
  excludedKeywords,
}: Props) => {
  const [
    parsedKeyword,
    parsedAdditionalKeywords,
    parsedAdditionalKeywords1,
    parsedAdditionalKeywords2,
    parsedAdditionalKeywords3,
    parsedExcludedKeywords,
  ] = keywordPreviewParser({
    keywords,
    additionalKeywords,
    additionalKeywords1,
    additionalKeywords2,
    additionalKeywords3,
    excludedKeywords,
  });
  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 18 }}>
        {parsedKeyword ||
        parsedAdditionalKeywords ||
        parsedExcludedKeywords ||
        parsedAdditionalKeywords1 ||
        parsedAdditionalKeywords2 ||
        parsedAdditionalKeywords3 ? (
          <Text
            style={{
              backgroundColor: '#fafafa',
              padding: 5,
            }}
          >
            <span style={{ color: '#605aa6' }}>{parsedKeyword}</span>
            <span style={{ color: '#00cd6e' }}>{parsedAdditionalKeywords}</span>
            <span style={{ color: '#00cd6e' }}>{parsedAdditionalKeywords1}</span>
            <span style={{ color: '#00cd6e' }}>{parsedAdditionalKeywords2}</span>
            <span style={{ color: '#00cd6e' }}>{parsedAdditionalKeywords3}</span>
            <span style={{ color: '#f44336' }}>{parsedExcludedKeywords}</span>
          </Text>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default KeywordsPreview;
