import { Table, TablePaginationConfig } from 'antd';
import { KEYWORDS_LIST_COLUMNS } from './TableColumns';
// import { IMailer } from 'shared/interfaces/IMailer';
// import { KEYWORDS_LIST_COLUMNS } from './ListColumn';

export const ExpandedTable = (
  record,
  handleDeleteKeyword,
  update_bucket,
  keywordPage,
  keywordPageTotal,
  keywordHandlePageChange,
  keywordLoading,
) => {
  const dataSource = record.keywords?.map((keyword) => ({
    ...keyword,
    bucket_id: record._id,
  }));
  return (
    <Table
      style={{ background: 'transparent' }}
      rowKey={record._id}
      columns={KEYWORDS_LIST_COLUMNS(handleDeleteKeyword, update_bucket)}
      loading={keywordLoading}
      dataSource={dataSource}
      pagination={{
        current: keywordPage,
        pageSize: 5,
        total: keywordPageTotal,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results`,
        position: ['bottomRight', 'topRight'],
        showSizeChanger: false,
      }}
      defaultExpandAllRows={false}
      onChange={({ current }: TablePaginationConfig) => {
        keywordHandlePageChange(current || 1);
      }}
    />
  );
};
