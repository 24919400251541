import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload as AntUpload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { UploadWrapper } from 'shared/theme/elements/Upload/Upload.styled';
// import { getBase64 } from 'shared/utils/getBase64';

type Props = {
  onChange: (file: any, result: string | ArrayBuffer | null) => void;
  onReaderLoad: (result: string | ArrayBuffer | null) => void;
  imageSrc?: string;
  accept?: string;
  disabled?: boolean;
  onRemove?: (file: any) => void;
  beforeUpload?: (file: any) => void;
};
const BulkUpload: React.FC<Props> = ({ onChange, accept, disabled, onRemove, onReaderLoad }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [imageUrl, setImageUrl] = useState<string>();

  const handleChange = (info: UploadChangeParam<UploadFile<Blob>> | any) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', () => onReaderLoad(reader.result));
    reader.readAsText(info.file);

    setIsLoading(false);
    onChange(info.file, reader.result);
  };

  // const handleBeforeUpload = (file: UploadChangeParam<UploadFile<Blob>> | any) => {
  //   const reader = new FileReader();

  //   reader.addEventListener('load', () => console.log(reader.result));
  //   reader.readAsText(file);

  //   return false;
  // };

  // useEffect(() => {
  //   setImageUrl(imageSrc);
  // }, [imageSrc]);

  return (
    <UploadWrapper>
      <AntUpload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        name="avatar"
        listType="picture"
        className="avatar-uploader"
        // showUploadList={false}
        onChange={handleChange}
        beforeUpload={() => false}
        accept={accept}
        disabled={disabled}
        onRemove={onRemove}
        multiple={true}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </AntUpload>
    </UploadWrapper>
  );
};

export default BulkUpload;
