import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import React from 'react';

import { InputLabel, LabelWrapper, RequiredLabel } from '../Input/Input.styled';
import { CRangePicker, DatePickerWrapper } from './DateRange.styled';

type Props = RangePickerProps<Date> & {
  label?: string;
  isRequired?: boolean;
  $fullWidth?: boolean;
};

// RangePickerProps<Date>

const DateRange: React.FC<Props> = ({ label, isRequired, $fullWidth, ...props }) => {
  return (
    <DatePickerWrapper $isFullWidth={$fullWidth}>
      {label && (
        <LabelWrapper>
          {isRequired && <RequiredLabel>*</RequiredLabel>}
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
      )}
      <CRangePicker {...props} />
    </DatePickerWrapper>
  );
};

export default DateRange;
