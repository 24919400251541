import { AxiosError } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { bucketDao } from 'shared/dao/bucketDao';

const { getBuckets: getBucketsDao, updateBucket: updateBucketDao, deleteBucket: deleteBucketDao } = bucketDao();
export const useBucketService = () => {
  const getBuckets = (payload: any) => {
    return useQuery<any, AxiosError>(['bucket', payload], () => getBucketsDao(payload));
  };

  const getSingleBucket = (payload: any) => {
    return useQuery<any, AxiosError>(['singleBucket', payload], () => getBucketsDao(payload));
  };

  const updateBucket = () => {
    return useMutation<any, AxiosError, any>((payload) => updateBucketDao(payload));
  };

  const deleteBucket = () => {
    const delete_bucket_mutation = useMutation((payload?: any) => deleteBucketDao(payload));
    return {
      delete_bucket_mutation,
    };
  };

  return {
    getBuckets,
    getSingleBucket,
    updateBucket,
    deleteBucket,
  };
};
