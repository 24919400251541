import { transformPayload } from 'shared/utils/transformPayload';
// import { useWebsiteGardenAxios } from 'pages/WebsiteGarden/List/useWGDummyData';
import { useAxios } from 'shared/hooks/useAxios';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import {
  // IWebsiteGardenCreatePayload,
  IWebsiteGardenRequestPayload,
  IWebsiteGardenResponsePayload,
  // IWebsiteGardenUpdatePayload,
} from 'shared/interfaces/IWebsiteGarden';

const { GET, POST, DELETE, PUT } = useAxios();

export const useWebsiteGardenDao = () => {
  const getWebsiteGarden = async (page = 1, payload?: IWebsiteGardenRequestPayload) => {
    const transformedPayload = transformPayload<IWebsiteGardenRequestPayload>(payload);

    const response = await GET<IWebsiteGardenResponsePayload>({
      url: ENDPOINTS.WEBSITEGARDEN,
      params: {
        token: transformedPayload,
        page,
      },
    });

    return response.data;
  };

  const getSingleWebsiteGarden = async (id?: string) => {
    const transformedPayload = transformPayload({ _id: id, limit: 1 });

    const response = await GET<IWebsiteGardenResponsePayload>({
      url: `${ENDPOINTS.WEBSITEGARDEN}/show`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const createWebsiteGarden = async (formData: FormData) => {
    const response = await POST({
      url: `${ENDPOINTS.WEBSITEGARDEN}/create`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const createBulkUpload = async (formData: FormData) => {
    const response = await POST({
      url: `${ENDPOINTS.WEBSITEGARDEN}/bulk`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const updateWebsiteGarden = async (formData: FormData) => {
    const response = await PUT({
      url: `${ENDPOINTS.WEBSITEGARDEN}/update`,
      data: formData,
      params: {
        token: formData.get('token'),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const deleteWebsiteGarden = async (id: string) => {
    const transformedPayload = transformPayload({ _id: id });

    const response = await DELETE({
      url: `${ENDPOINTS.WEBSITEGARDEN}/delete`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  return {
    getWebsiteGarden,
    getSingleWebsiteGarden,
    deleteWebsiteGarden,
    createWebsiteGarden,
    createBulkUpload,
    updateWebsiteGarden,
  };
};
