import { IKeyValue } from 'shared/interfaces/utils/IKeyValue';

export enum WEBSITEGARDENSORT {
  NAMEASC = '+website_name',
  NAMEDESC = '-website_name',
}

export const CLASSIFICATIONS_FILTER: IKeyValue[] = [
  {
    id: 'Blog',
    value: 'Blog',
  },
  {
    id: 'News',
    value: 'News',
  },
];

export const MAIN_INTEREST_FILTER: IKeyValue[] = [
  {
    id: 'Technology',
    value: 'Technology',
  },
  {
    id: 'Business and Industry',
    value: 'Business and Industry',
  },
  {
    id: 'Entertainment',
    value: 'Entertainment',
  },
  {
    id: 'Food and Drink',
    value: 'Food and Drink',
  },
  {
    id: 'Family and Relationships',
    value: 'Family and Relationships',
  },
];

export const FOCUS_FILTER: IKeyValue[] = [
  {
    id: 'Health Care',
    value: 'Health Care',
  },
  {
    id: 'Cryptocurrency',
    value: 'Cryptocurrency',
  },
  {
    id: 'Travel',
    value: 'Travel',
  },
  {
    id: 'Aviation',
    value: 'Aviation',
  },
  {
    id: 'Politics and Social Issues',
    value: 'Politics and Social Issues',
  },
];
