import { Modal } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SpinnerWrapper } from 'components/SpinnerWrapper';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { BreadcrumbContext } from 'shared/context/Breadcrumb';
import { useDebouncedState } from 'shared/hooks/useDebouncedState';
import { ICountry } from 'shared/interfaces/ICountry';
import { IInfluencerForm } from 'shared/interfaces/IInfluencers';
import { useCountryService } from 'shared/services/countryService';
import { useInfluencersService } from 'shared/services/influencersService';
import { Alert } from 'shared/theme/elements';
import { mergeSelectOptions } from 'shared/utils/mergeSelectOptions';
import { useInfluencerForm } from './AddInfluencerForm';
//
import AddInfluencerView from './AddInfluencerView';
//

const { confirm } = Modal;

export interface InfluencerPageSearch {
  country?: string;
}

export interface InfluencerPageSearchOptions {
  country?: DefaultOptionType[];
}

export interface InfluencerPageSearchLoading {
  country?: boolean;
}

const AddInfluencerContainer = () => {
  const { location, push } = useHistory();
  const locationState = location.state as any;
  const user = localStorage?.getItem('user_admin') ? JSON.parse(localStorage.getItem('user_admin') || '') : null;
  const queryClient = useQueryClient();

  // Influencer ID
  const INFLUENCER_ID = useMemo(() => {
    if (locationState?.id) return locationState.id;

    return undefined;
  }, [locationState]);

  // CONTEXT
  const { setAdditionalValue } = useContext(BreadcrumbContext);

  // INFLUENCER SERVICE

  const { getSingleInfluencer, createInfluencer, updateInfluencer } = useInfluencersService();
  const { createMutation } = createInfluencer();
  const { updateMutation } = updateInfluencer();

  // GET SINGLE INFLUENCER
  const {
    data: influencerData,
    isLoading: influencerIsLoading,
    isFetching: influencerIsFetching,
  } = getSingleInfluencer(INFLUENCER_ID);

  // Booleans
  const isEdit = useMemo(() => (INFLUENCER_ID ? true : false), [INFLUENCER_ID]);

  // <== Country Service
  const [countrySearch, setCountrySearch] = useDebouncedState<string | undefined>(undefined, 800);
  const { getCountries } = useCountryService();
  const { data: countryData, isLoading: countryLoading } = getCountries({
    country_name: countrySearch,
    limit: 20,
  });
  const countryOptions = useCallback(() => {
    let intialValues: DefaultOptionType[] | undefined = undefined;

    const country = countryData?.data?.map(({ country_name }: ICountry) => ({
      value: country_name,
      label: country_name,
    }));

    if (influencerData) {
      intialValues = [
        {
          value: influencerData?.[0]?.social_poster_country?.toUpperCase(),
          label: influencerData?.[0]?.social_poster_country?.toUpperCase(),
        },
      ];
    }

    return mergeSelectOptions(country, intialValues);
  }, [influencerData, countryData?.data]);
  // Country Service ==>

  // SET INFLUENCER PROFILE AVATAR
  const [avatar, setAvatar] = useState<{ url?: string; file?: File }>({
    url: undefined,
    file: undefined,
  });

  // console.log(avatar);

  // Single Influencer
  const singleInfluencerData = useMemo(() => influencerData?.[0], [influencerData]);

  const submitInfluencer = (formValues: IInfluencerForm) => {
    // const influencer = influencerData?.[0];

    //
    return new Promise((resolve, reject) => {
      if (!isEdit) {
        createMutation.mutate(
          {
            data: {
              ...formValues,
              created_by: user?._id,
            },
            avatar: avatar.file,
          },
          {
            onSuccess: () => {
              resolve(true);
              Alert.success('Influencer Created!');
              queryClient.invalidateQueries('influencers');
              push(ROUTES.INFLUENCERS.LIST.LIST, {
                filter: locationState?.filter,
                page: locationState?.page,
              });
            },
            onSettled: () => {
              (async () => await queryClient.refetchQueries('influencers'))();
            },
            onError: (error: any) => {
              reject(error);
              if (error?.response?.data?.server_response) {
                Alert.error(error.response.data.error_message);
              } else {
                Alert.error('Something Went Wrong');
              }
            },
          },
        );
      } else if (isEdit && singleInfluencerData) {
        updateMutation.mutateAsync(
          {
            data: {
              ...formValues,
              _id: singleInfluencerData?._id,
              updated_by: user?._id,
            },
            avatar: avatar.file,
          },
          {
            onSuccess: () => {
              resolve(true);
              Alert.success('', 'Influencer Updated!');
              queryClient.invalidateQueries('influencers');
              queryClient.invalidateQueries('single-influencer');
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              form.resetForm();
              push(ROUTES.INFLUENCERS.LIST.LIST);
            },
            onSettled: () => {
              (async () => await queryClient.refetchQueries('influencers'))();
            },
            onError: (error: any) => {
              reject(error);
              if (error?.response?.data?.server_response) {
                Alert.error('', error.response.data.error_message);
              } else {
                Alert.error('', 'Failed to Update Influencers');
              }
            },
          },
        );
      }
    });

    //
  };

  // Change text handle on Modal if Add or Update
  const addOrUpdate = () => {
    const changeTitle = isEdit ? 'Update Influencer' : 'Add Influencer';
    const changeContent = isEdit
      ? 'Are you sure you want to Update this Influencer?'
      : 'Are you sure you want to Add this Influencer?';

    return { changeTitle, changeContent };
  };

  const { changeTitle, changeContent } = addOrUpdate();

  const handleOnSubmit = (form: IInfluencerForm) => {
    confirm({
      centered: true,
      title: changeTitle,
      content: changeContent,
      onOk: async () => await submitInfluencer(form),
      okButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      cancelButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      okText: 'Yes',
    });
  };

  const handleOnSearch = (key: keyof InfluencerPageSearch, value: any) => {
    switch (key) {
      case 'country':
        setCountrySearch(value);
        break;
    }
  };

  // Search Options
  const options: InfluencerPageSearchOptions = {
    country: useMemo(() => countryOptions(), [countryOptions]),
  };

  // Search Loading
  const searchLoading: InfluencerPageSearchLoading = useMemo(
    () => ({
      country: countryLoading,
    }),
    [countryLoading],
  );

  //
  const getInitialValues = () => {
    if (!influencerData?.[0]) return undefined;

    const country = influencerData?.[0]?.social_poster_country;

    const data = {
      ...influencerData?.[0],
      country: country ? country.toUpperCase() : country,
    };

    return data;
  };

  // Influencer Form
  const { form } = useInfluencerForm({
    onSubmit: handleOnSubmit,
    initialValue: getInitialValues(),
  });

  const influencer = useMemo(
    () => ({
      loading: influencerIsLoading,
      fetching: influencerIsFetching,
      data: influencerData?.[0],
    }),
    [influencerData, influencerIsFetching, influencerIsLoading],
  );

  //

  // ADD INFLUENCER NAME ON EDIT IN BREADCRUMB
  useEffect(() => {
    setAdditionalValue(influencerData?.[0].social_poster_username);

    return () => {
      setAdditionalValue(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencerData]);

  //
  return (
    <div>
      <SpinnerWrapper spinning={influencerIsLoading || influencerIsFetching} position="top">
        <AddInfluencerView
          //
          form={form}
          //
          influencer={influencer}
          //
          onSearch={handleOnSearch}
          searchOptions={options}
          searchLoading={searchLoading}
          //
          setAvatar={setAvatar}
          avatarUrl={avatar.url}
          //
          isEdit={isEdit}
          //
          isGoingToVerify={singleInfluencerData ? true : false}
          //
        />
      </SpinnerWrapper>
    </div>
  );
};

export default AddInfluencerContainer;
