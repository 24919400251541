import styled from 'styled-components';

export const IconContainer = styled.div`
  .anticon {
    font-size: 18px;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      color: rgba(60, 60, 60);
    }
  }
`;
