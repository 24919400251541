import React from 'react';
import { Card, Heading } from 'shared/theme/elements';

const NotFoundView = () => {
  return (
    <Card $fullHeight>
      <Heading style={{ textAlign: 'center' }} $variant="primary">
        You're not permitted to access this page
      </Heading>
      <p className="text-center text-gray-500 h-full">Please contact your administrator to get access on this page</p>
    </Card>
  );
};

export default NotFoundView;
