import React from 'react';
import { authService } from 'shared/services/authService';

import { ProfileDetails, ProfileEmailText, ProfileImage, ProfileNameText, ProfileWrapper } from './Sidebar.styled';

type Props = {
  collapsed: boolean;
};
const { getProfile } = authService();
const SidebarProfile: React.FC<Props> = ({ collapsed }) => {
  const profileDetail = getProfile();
  const getFullName = `${profileDetail?.first_name} ${profileDetail?.last_name}`;

  return (
    <ProfileWrapper isCollapsed={collapsed}>
      <ProfileImage src={`https://ui-avatars.com/api/?name=${getFullName}`} preview={false} />
      {!collapsed && (
        <ProfileDetails>
          <>
            <ProfileNameText ellipsis={true}>{getFullName}</ProfileNameText>
            <ProfileEmailText ellipsis={true}>{[profileDetail?.email]}</ProfileEmailText>
          </>
        </ProfileDetails>
      )}
    </ProfileWrapper>
  );
};

export default SidebarProfile;
