import { LogoutOutlined } from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SIDEBAR_MENU_LIST } from 'shared/constants/SIDEBAR';
import { SidebarMenuItemProps } from 'shared/interfaces/utils/ISidebar';
import { authService } from 'shared/services/authService';

import { Menu, MenuContainer } from './Sidebar.styled';

interface Props {
  collapsed?: boolean;
}

const { SubMenu, Item } = AntMenu;

const { logout } = authService();
const SidebarMenu = ({ collapsed }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();

  // GET LOCAL STORAGE USER ROLE
  const current_user_role: string[] =
    localStorage.getItem('user_roles') === undefined || localStorage.getItem('user_roles') === null
      ? []
      : JSON.parse(localStorage.getItem('user_roles') || '');

  const role: string =
    localStorage.getItem('role') === undefined || localStorage.getItem('role') === null
      ? ''
      : JSON.parse(localStorage.getItem('role') || '');

  const getActiveMenu = useMemo(() => {
    const [, parent, childParent, subChild] = pathname.split('/');

    return subChild ? [parent.toLowerCase(), childParent.toLowerCase()] : [parent.toLowerCase()];
  }, [pathname]);

  const getSelectedKey = useMemo(() => {
    const [, parent, child, subChild] = pathname.split('/');
    return subChild ? `/${parent}/${child}/${subChild}`.toLowerCase() : `/${parent}/${child}`.toLowerCase();
  }, [pathname]);

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  const SidebarMenuItemTitle = (title: string, tag?: string, tagColor?: string) => {
    if (tag) {
      return (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }} key={title.toLocaleLowerCase()}>
          <div>{title}</div>
          <div
            style={{
              backgroundColor: tagColor || 'white',
              fontSize: '9px',
              borderRadius: '2px',
              fontWeight: 'bold',
              maxHeight: '18px',
              width: '18px',
              display: 'grid',
              placeContent: 'center',
              color: 'white',
            }}
          >
            {tag}
          </div>
        </div>
      );
    }

    return title;
  };

  const SidebarSubMenu = (menu: Array<SidebarMenuItemProps>) => {
    // const { id, name, link, icon, subMenu } = menu;
    return menu.map(({ children, title, icon, to, tag, tagColor, isTitle, titleColor, titleCollapsed }) => {
      if (isTitle) {
        return (
          <Menu.Item disabled key={title.toLocaleLowerCase()}>
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                color: titleColor ? titleColor : 'white',
                display: 'flex',
                justifyContent: collapsed ? 'center' : 'normal',
              }}
            >
              {collapsed ? titleCollapsed?.toUpperCase() || title.toUpperCase() : title.toUpperCase()}
            </span>
          </Menu.Item>
        );
      }
      return children ? (
        <SubMenu key={title.toLowerCase()} icon={icon} title={SidebarMenuItemTitle(title, tag, tagColor)}>
          {SidebarSubMenu(children)}
        </SubMenu>
      ) : (
        <Menu.Item key={to?.toLowerCase() || ''} icon={icon} onClick={() => history.push(to || '#')}>
          {SidebarMenuItemTitle(title, tag, tagColor)}
        </Menu.Item>
      );
    });
  };

  return (
    <MenuContainer>
      <Menu defaultOpenKeys={getActiveMenu} selectedKeys={[getSelectedKey]} mode="inline" theme="dark" className="main">
        {/* {SIDEBAR_MENU_LIST.map(({ Icon, ...item }, index) => (
        <SubMenu className="submenu-subparent" icon={Icon} title={item.title} key={item.title.toLowerCase()}>
          {item.children.map((child, childIndex) => (
            <>
              {child.subChild ? (
                <>
                  <SubMenu key={child.title.toLowerCase()} title={child.title} className="submenu-subchild">
                    {child.subChild.map((subChild, subChildIndex) => (
                      <Item key={subChild.to ? subChild.to : `${index} ${childIndex} ${subChildIndex}`}>
                        <Link to={subChild.to || '#'}>{subChild.title}</Link>
                      </Item>
                    ))}
                  </SubMenu>
                </>
              ) : (
                <Item key={child.to ? child.to : `${index} ${childIndex}`}>
                  <Link to={child.to || '#'}>{child.title}</Link>
                </Item>
              )}
            </>
          ))}
        </SubMenu>
      ))} */}

        {SidebarSubMenu(SIDEBAR_MENU_LIST({ current_user_role, role }))}
      </Menu>
      <Menu mode="inline" theme="dark" className="logout">
        <Item key="logout" icon={<LogoutOutlined />} onClick={() => handleLogout()} className="menu__logout">
          Logout
        </Item>
      </Menu>
    </MenuContainer>
  );
};

export default React.memo(SidebarMenu);
