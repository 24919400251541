import { FormikProps, useFormik } from 'formik';
import { IInfluencerForm, IInfluencers } from 'shared/interfaces/IInfluencers';
import { toTitleCase } from 'shared/utils/toTitleCase';
import * as yup from 'yup';

interface Props {
  onSubmit: (values: IInfluencerForm) => void;
  initialValue?: IInfluencers;
}

export const useInfluencerForm = ({ onSubmit, initialValue }: Props) => {
  // //

  const getInitialValue = (
    key: keyof IInfluencers,
    defaultValue: any,
    cb?: (key: string, record: IInfluencers) => any,
  ) => {
    if (initialValue) {
      if (cb) {
        const value = cb(key, initialValue);
        return value;
      }

      if (!initialValue?.[key]) {
        return defaultValue;
      }

      return initialValue[key];
    } else {
      return defaultValue;
    }
  };

  const URL =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const InfluencerSchema: yup.SchemaOf<IInfluencerForm> = yup.object().shape({
    //
    social_poster_source: yup.string(),
    social_poster_profile_img: yup.string().matches(URL, 'Enter correct Image URL!').required().label('Profile Image'),
    social_poster_profile_url: yup.string().matches(URL, 'Enter correct Profile URL!').required().label('Profile URL'),
    social_poster_username: yup
      .string()
      .min(4, 'Username must be at least 4 characters')
      .required()
      .label('Profile Username'),
    social_poster_name: yup.string().min(4, 'Full Name must be at least 4 characters').required().label('Full Name'),
    social_poster_fullname: yup.string(),
    social_poster_country: yup.string().required('Country is required'),
    //
    social_poster_interests: yup.array().of(yup.string()).notRequired().label('Social Media Links').nullable(),
    social_media_links: yup.array().of(yup.string()).notRequired().label('Social Media Links'),
    //
    social_poster_content_count: yup.number().required().label('Content Count'),
    social_poster_follower_count: yup.number().required().label('Follower Count'),
    social_poster_following_count: yup.number().required().label('Following Count'),
    social_poster_likes_count: yup.number().required().label('Likes Count'),
    social_poster_views_count: yup.number().when('social_poster_source', {
      is: 'youtube',
      then: yup.number().required().label('Views Count'),
    }),
    //
    social_poster_board_count: yup.number().default(0),
    social_poster_business: yup.string(),
    social_poster_identifier: yup.string(),
    social_poster_latest_post: yup.string().nullable(),
    social_poster_list_count: yup.number().default(0),
    social_poster_pin_count: yup.number().default(0),
    social_poster_processing_machine: yup.string().nullable(),
    social_poster_type: yup.string(),
    social_poster_url: yup.string(),
    scraped_from: yup.string().nullable(),
    social_poster_profile_banner: yup.string(),
    social_poster_profile: yup
      .string()
      .min(4, 'Poster Profile must be at least 4 characters')
      .required()
      .label('Poster Profile'),
    social_poster_scrape_datetime: yup.string().nullable(),
    social_poster_started_date: yup.string().nullable(),
    social_poster_verified: yup.string(),
    //
    social_poster_account_status: yup.string(),
    //
  });

  const initialValues: IInfluencerForm = {
    //
    social_poster_source: getInitialValue('social_poster_source', 'twitter', (_, record) => {
      return toTitleCase(
        Object.entries(record?.social_poster_source)
          .map(([, value]) => value)
          .filter((value) => value || value !== null)
          .join(''),
      );
    }),
    social_poster_profile_img: getInitialValue('social_poster_profile_img', ''),
    social_poster_username: getInitialValue('social_poster_username', ''),
    social_poster_name: getInitialValue('social_poster_name', ''),
    social_poster_country: getInitialValue('social_poster_country', 'PHILIPPINES'),
    //
    social_poster_interests: getInitialValue('social_poster_interests', [], (_, record) => {
      return record.social_poster_interests;
    }),

    social_poster_content_count: getInitialValue('social_poster_content_count', 0),
    social_poster_follower_count: getInitialValue('social_poster_follower_count', 0),
    social_poster_following_count: getInitialValue('social_poster_following_count', 0),
    social_poster_likes_count: getInitialValue('social_poster_likes_count', 0),
    //
    social_poster_board_count: getInitialValue('social_poster_board_count', 0),
    social_poster_business: getInitialValue('social_poster_business', ''),
    social_poster_identifier: getInitialValue('social_poster_identifier', ''),
    social_poster_latest_post: getInitialValue('social_poster_latest_post', ''),
    social_poster_list_count: getInitialValue('social_poster_list_count', 0),
    social_poster_pin_count: getInitialValue('social_poster_pin_count', 0),
    social_poster_processing_machine: getInitialValue('social_poster_processing_machine', ''),
    social_poster_type: getInitialValue('social_poster_type', ''),
    social_poster_url: getInitialValue('social_poster_url', ''),
    social_poster_views_count: getInitialValue('social_poster_views_count', 0),
    scraped_from: getInitialValue('scraped_from', ''),
    social_poster_profile_banner: getInitialValue('social_poster_profile_banner', ''),
    social_poster_profile_url: getInitialValue('social_poster_profile_url', ''),
    social_poster_profile: getInitialValue('social_poster_profile', ''),
    social_poster_scrape_datetime: getInitialValue('social_poster_scrape_datetime', ''),
    social_poster_started_date: getInitialValue('social_poster_started_date', ''),
    social_poster_verified: getInitialValue('social_poster_verified', 'False'),
    //
    social_poster_account_status: getInitialValue('social_poster_account_status', 'ACTIVE'),
    //,
  };

  const handleBeforeSubmit = (values: IInfluencerForm): IInfluencerForm => {
    const data: IInfluencerForm = {
      ...values,
    };
    return data;
  };

  const handleOnSubmit = (values: IInfluencerForm) => {
    // here: handle data before submit
    const data = handleBeforeSubmit(values);

    onSubmit(data as IInfluencerForm);
  };

  const form: FormikProps<IInfluencerForm> = useFormik<IInfluencerForm>({
    initialValues,
    onSubmit: (values) => handleOnSubmit(values as IInfluencerForm),
    validationSchema: InfluencerSchema,
    enableReinitialize: true,
  });

  // //
  return {
    form,
  };
  // //
};
