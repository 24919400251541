import { Checkbox, Dropdown, Menu } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC, useState } from 'react';
import { Button } from 'shared/theme/elements';

import { DropdownCopyMenu, DropdownCopyMenuItem } from './DropdownCopy.styled';

interface Props {
  current?: string;
  items: Array<{
    id: string;
    name: string;
  }>;
  copyButtonTitle?: string;
  disabled?: boolean;
  onCopy?: (origin: string | undefined, destination: Array<string> | undefined) => void;
}

const DropdownCopy: FC<Props> = ({ current, items, copyButtonTitle, disabled, onCopy, children }) => {
  const [checkedItems, setChecked] = useState<Array<string>>([`${current}`]);
  const [isSelectAll, setSelectAll] = useState<boolean>(false);

  const handleOnCheckChange = (value: any) => {
    setChecked(value);
  };

  const selectAll = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const values = items.map((item) => item.id);
      setChecked(values);
      return;
    }
    setChecked([`${current}`]);
  };

  const handleSubmit = () => {
    if (onCopy) {
      onCopy(current, checkedItems);
    }
    setSelectAll(false);
    setChecked([`${current}`]);
  };

  const getOptions = () => {
    return items.map((item) => ({
      label: item.name,
      value: item.id,
      disabled: current === item.id ? true : false,
    }));
  };

  const DropdownCard = () => (
    <DropdownCopyMenu>
      <DropdownCopyMenuItem>
        <Checkbox checked={isSelectAll} onChange={selectAll}>
          Select All
        </Checkbox>
      </DropdownCopyMenuItem>
      <Menu.Divider></Menu.Divider>
      <DropdownCopyMenuItem key="items">
        <Checkbox.Group
          options={getOptions()}
          defaultValue={[`${current}`]}
          value={checkedItems}
          onChange={handleOnCheckChange}
        ></Checkbox.Group>
      </DropdownCopyMenuItem>
      <Menu.Divider></Menu.Divider>
      <DropdownCopyMenuItem key="button">
        <Button onClick={handleSubmit} $fullwidth disabled={disabled} size="middle">
          {copyButtonTitle ? copyButtonTitle : 'Copy'}
        </Button>
      </DropdownCopyMenuItem>
    </DropdownCopyMenu>
  );

  return (
    <Dropdown overlay={<DropdownCard />} placement="bottomRight" trigger={['click']}>
      {children}
    </Dropdown>
  );
};

export default DropdownCopy;
