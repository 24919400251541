import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)``;
export const StyledRadioGroup = styled(Radio.Group)`
  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #292f4c;
    color: white !important;
    border-color: #292f4c;
    border-right: #292f4c;
  }
`;
