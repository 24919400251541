import React, { useMemo } from 'react';
import { Input, Typography, Modal, Checkbox, List } from 'antd';

const { Search } = Input;
const { Text } = Typography;

interface Props {
  copyForm: any;
  categories: any;
  categoriesLoading?: boolean;
  modalOpen: boolean;
  handleOk: (action: string) => void;
  handleCancel: (action: string) => void;
  setCategoryName: React.Dispatch<React.SetStateAction<string>>;
  setCategoryID: React.Dispatch<React.SetStateAction<any[]>>;
  categoryName: string;
}
const CopyBuckets = ({
  copyForm,
  categories,
  categoriesLoading,
  modalOpen,
  handleOk,
  handleCancel,
  setCategoryName,
  setCategoryID,
  categoryName,
}: Props) => {
  const all_categories = useMemo(() => {
    const categoryList: any[] = categories.filter((item) => item.category_name !== categoryName);

    return categoryList?.map((datas: any) => {
      return {
        _id: datas._id,
        category_name: datas.category_name,
        key: datas._id,
        index: datas._id,
      };
    });
  }, [categories, categoryName]);

  return (
    <>
      <Modal
        title={`Copy Bucket to ${copyForm.values.platform}`}
        open={modalOpen}
        onOk={() => handleOk('copy_to_other_platforms')}
        onCancel={() => handleCancel('copy_to_other_platforms')}
        okText="Copy"
      >
        <Search
          allowClear
          placeholder="Search Category Name"
          style={{ width: '100%' }}
          onSearch={(value) => setCategoryName(value)}
        />
        <Checkbox.Group style={{ width: '100%', marginTop: 10 }} onChange={(e) => setCategoryID(e)}>
          <List
            bordered={false}
            dataSource={all_categories}
            loading={categoriesLoading}
            renderItem={(item: any) => (
              <List.Item key={item._id}>
                <Checkbox key={item._id} value={item._id}>
                  <Text>{item.category_name}</Text>
                </Checkbox>
              </List.Item>
            )}
          />
        </Checkbox.Group>
      </Modal>
    </>
  );
};

export default CopyBuckets;
