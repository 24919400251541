import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IClientSubscriptionRequestPayload,
  IClientSubscriptionResponsePayload,
} from 'shared/interfaces/IClientSubscription';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET, POST } = useAxios();
export const clientSubscriptionDao = () => {
  const getClientSubscription = async (payload: IClientSubscriptionRequestPayload) => {
    const tranformedPayload = transformPayload<IClientSubscriptionRequestPayload>(payload);

    const response = await GET<IClientSubscriptionResponsePayload>({
      url: `${ENDPOINTS.CLIENT_SUBSCRIPTION.SINGLE}`,
      params: {
        token: tranformedPayload,
      },
    });

    return response.data;
  };

  const updateClientSubscription = async (payload: any) => {
    const tranformedPayload = transformPayload<any>(payload);
    const response = await POST<any>({
      url: `${ENDPOINTS.CLIENT_SUBSCRIPTION.UPDATE}`,
      params: {
        token: tranformedPayload,
      },
    });

    return response.data;
  };

  return {
    getClientSubscription,
    updateClientSubscription,
  };
};
