import React from 'react';
import { Input } from 'antd';

interface Props {
  value: any;
  type: string;
  onChange: any[];
  // onChange: (type: string, value: any) => void;
  onKeyUp: (e: any, type: number) => void;
}

const AdditionalKeywords = ({ value, type, onChange, onKeyUp }: Props) => {
  const match_keywords: string[] = [
    'additionalKeywordsMain1',
    'additionalKeywordsAdditional1',
    'additionalKeywordsExcluded1',
  ];

  return (
    <>
      <Input.TextArea
        autoSize
        placeholder={'Add Additional Keywords'}
        value={value}
        onChange={(e) => {
          onChange[0](type, e.target.value);
        }}
        onKeyUp={(e) => onKeyUp(e, type === match_keywords[0] ? 11 : type === match_keywords[1] ? 21 : 31)}
      />
    </>
  );
};

export default AdditionalKeywords;
