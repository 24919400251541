import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { ILoginRequestPayload } from 'shared/interfaces/IAuth';
import { authService } from 'shared/services/authService';
import { useUsersService } from 'shared/services/usersService';
import { Alert } from 'shared/theme/elements';
import { ls } from 'shared/utils/ls';

import LoginView from './LoginView';

//
const { getSingleUser } = useUsersService();

const { getProfile } = authService();
const LoginContainer = () => {
  const userProfile = getProfile();
  const [userID, setUserID] = useState<string | undefined>(userProfile?._id);

  const { login } = authService();

  const { data: loggedUserData } = getSingleUser(userID);

  const user_roles: any = loggedUserData?.map((data) => data.roles);
  const role_label_name: any = loggedUserData?.map((data) => data.role_label_name);

  const { loginMutation } = login();
  const { push } = useHistory();
  const handleSubmit = (payload: ILoginRequestPayload) => {
    loginMutation.mutate(payload, {
      onSuccess: (response) => {
        setUserID(response.data[0]._id);
        ls.set('user_admin', response.data[0]);
      },
      onError: (error) => {
        if (error.response?.data.server_response) {
          Alert.error('', error.response?.data.server_response);
        }
      },
    });
  };

  useEffect(() => {
    if (loggedUserData) {
      ls.set('user_roles', user_roles[0]);
      ls.set('role', role_label_name[0]);

      const current_user_role: string[] =
        localStorage.getItem('user_roles') === undefined || localStorage.getItem('user_roles') === null
          ? []
          : JSON.parse(localStorage.getItem('user_roles') || '');

      const role: string =
        localStorage.getItem('role') === undefined || localStorage.getItem('role') === null
          ? ''
          : JSON.parse(localStorage.getItem('role') || '');

      setTimeout(() => {
        if (role === 'BD/CS') {
          current_user_role.push('access_admin_users_management');
        }

        if (role === 'Operations') {
          current_user_role.push('access_admin_users_management');
          current_user_role.push('access_clients');
        }

        ls.set('user_roles', current_user_role);
        ls.set('role', role);

        switch (role) {
          case 'CEO/COO/CIO':
            push(ROUTES.ADMIN.CLIENTS.LIST);
            break;
          case 'Operations':
            push(ROUTES.ADMIN.CLIENTS.LIST);
            break;
          case 'Curation':
            push(ROUTES.AUTHORS.OVERVIEW.LIST);
            break;
          case 'BD/CS':
            push(ROUTES.ADMIN.CLIENTS.LIST);
            break;
          case 'IT Team':
            push(ROUTES.ADMIN.USERS.LIST);
            break;
          default:
            push(ROUTES.ADMIN.CLIENTS.LIST);
        }
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserData]);

  return <LoginView handleSubmit={handleSubmit} loading={loginMutation.isLoading} />;
};

export default LoginContainer;
