import { useQuery } from 'react-query';
import { useCountryDao } from 'shared/dao/countryDao';
import { ICountryRequestPayload, ICountryResponsePayload } from 'shared/interfaces/ICountry';

const { getCountries: getCountriesDao } = useCountryDao();
export const useCountryService = () => {
  const getCountries = (payload?: ICountryRequestPayload) => {
    return useQuery<ICountryResponsePayload, Error>(['country', payload], () => getCountriesDao(payload));
  };

  return {
    getCountries,
  };
};
