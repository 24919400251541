import { Dispatch, SetStateAction, useState } from 'react';
import { useDebounce } from 'use-debounce';

export function useDebouncedState<S = undefined>(
  data?: S,
  delay?: number,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const [state, setState] = useState(data);
  const [debouncedState] = useDebounce(state, delay || 0);

  return [debouncedState, setState];
}
