import React from 'react';
import { Modal, Typography } from 'antd';

const { Text } = Typography;

interface Props {
  isModalOpen: boolean;
  handleOk: (action: string) => void;
  onCancel: (action: string) => void;
  deleteForm: any;
}
const DeleteBucket = ({ isModalOpen, handleOk, onCancel, deleteForm }: Props) => {
  return (
    <Modal
      title="Delete Bucket"
      open={isModalOpen}
      onOk={() => handleOk('delete')}
      onCancel={() => onCancel('delete')}
      okText="Delete"
    >
      <Text>
        Are you sure you want to delete <span style={{ color: '#0000FF' }}>{deleteForm.values.bucket_name}</span> ?
      </Text>
    </Modal>
  );
};

export default DeleteBucket;
