import { DefaultOptionType } from 'antd/lib/select';

/**
 *
 * @param options list
 * @param initialValues
 * @returns options with initialValues included
 */
export const mergeSelectOptions = (
  options?: DefaultOptionType[],
  initialValues?: DefaultOptionType[],
): DefaultOptionType[] | undefined => {
  if (!options) return undefined;

  const data: DefaultOptionType[] = options;

  if (initialValues) {
    initialValues.forEach((initialValue) => {
      const exists = options.find((option) => option.value === initialValue.value);

      if (!exists) data.push(initialValue);
    });
  }

  return data;
};
