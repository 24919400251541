import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  option: {
    label: string;
    path?: string | undefined;
  };
}

const BucketsBreadCrumb = ({ option }: Props) => {
  const location = useLocation();
  const locationState = location.state as any;

  return (
    <Link
      to={{
        pathname: `${option.path?.replace('buckets', 'category/buckets')}/list`,
        state: {
          buckets: locationState?.buckets_all,
          client_id: locationState?.client_id,
          platform: locationState?.platform,
          _id: locationState?._id,
          comingFromCreateOfViewBucketList: locationState?.comingFromViewBucketList ? true : false,
          category_name: locationState?.category_name,
        },
      }}
      style={{
        pointerEvents: `${
          // Disable click redirect bucket breadcrumb
          (Array.isArray(locationState?.buckets) && locationState.buckets.length === 0) ||
          locationState?.inSpecificBucketList === true ||
          locationState?.comingfromCategoryList === true ||
          locationState?.comingFromCreateOfViewBucketList === true
            ? 'none'
            : 'inherit'
        }`,
      }}
    >
      {option.label}
    </Link>
  );
};

export default BucketsBreadCrumb;
