import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import styled from 'styled-components';

const DatePicker = generatePicker(dateFnsGenerateConfig);

export const CDatePicker = styled(DatePicker)``;

CDatePicker.defaultProps = {
  size: 'large',
  picker: 'date',
};

export const DatePickerWrapper = styled.div<{
  $isFullWidth?: boolean;
  $haserror?: boolean;
}>`
  width: 100%;
  > ${CDatePicker} {
    &.ant-picker {
      width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
    }

    &.ant-picker-status-error {
      border-color: ${({ $haserror }) => $haserror && '#cc0033'};
      background-color: ${({ $haserror }) => $haserror && '#fce4e4'};
    }

    &.ant-picker-status-error:hover {
      border-color: ${({ $haserror }) => $haserror && '#cc0033'};
      background-color: ${({ $haserror }) => $haserror && '#fce4e4'};
    }
  }
`;

export const ErrorText = styled.label`
  display: block;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.errorColor};
`;
