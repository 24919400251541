import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';

interface Props {
  name: string;
  categoryName?: string;
  clientID?: string;
}
const Breadcrumbs = ({ name, categoryName, clientID }: Props) => {
  const { push, goBack } = useHistory();
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a onClick={() => push(ROUTES.ADMIN.CLIENTS.CATEGORY, { id: clientID, default_tab: 'categories' })}>Category</a>
      </Breadcrumb.Item>
      {categoryName ? (
        <Breadcrumb.Item>
          {/* <a onClick={() => push(ROUTES.ADMIN.CLIENTS.CATEGORY)}>{categoryName}</a> */}
          <a onClick={() => goBack()}>{categoryName}</a>
        </Breadcrumb.Item>
      ) : undefined}
      {/* <Breadcrumb.Item>
        <a href="">{categoryName}</a>
      </Breadcrumb.Item> */}
      <Breadcrumb.Item>{name}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
