export interface IKeywordListProps {
  keywords: string[];
  additionalKeywords: string[];
  additionalKeywords1: string[];
  additionalKeywords2: string[];
  additionalKeywords3: string[];
  excludedKeywords: string[];
}

const mappedString = (string: string[]) => string.map((str) => `"${str}"`);

export const keywordPreviewParser = ({
  keywords,
  additionalKeywords,
  additionalKeywords1,
  additionalKeywords2,
  additionalKeywords3,
  excludedKeywords,
}: IKeywordListProps) => {
  let parsedKeyword = '';
  let parsedAdditionalKeywords = '';
  let parsedAdditionalKeywords1 = '';
  let parsedAdditionalKeywords2 = '';
  let parsedAdditionalKeywords3 = '';
  let parsedExcludedKeywords = '';
  if (keywords?.length > 0) {
    const mappedKeywords = mappedString(keywords);
    parsedKeyword = `(${mappedKeywords.join(' OR ')})`;
  }

  if (additionalKeywords?.length > 0) {
    const mappedAdditionalKeywords = mappedString(additionalKeywords);
    parsedAdditionalKeywords = ` AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }

  if (additionalKeywords1?.length > 0) {
    const mappedAdditionalKeywords1 = mappedString(additionalKeywords1);
    parsedAdditionalKeywords1 = ` AND (${mappedAdditionalKeywords1.join(' OR ')})`;
  }

  if (additionalKeywords2?.length > 0) {
    const mappedAdditionalKeywords2 = mappedString(additionalKeywords2);
    parsedAdditionalKeywords2 = ` AND (${mappedAdditionalKeywords2.join(' OR ')})`;
  }

  if (additionalKeywords3?.length > 0) {
    const mappedAdditionalKeywords3 = mappedString(additionalKeywords3);
    parsedAdditionalKeywords3 = ` AND (${mappedAdditionalKeywords3.join(' OR ')})`;
  }

  if (excludedKeywords?.length > 0) {
    const mappedExcludedKeywords = mappedString(excludedKeywords);
    parsedExcludedKeywords = ` AND NOT (${mappedExcludedKeywords.join(' OR ')})`;
  }

  return [
    parsedKeyword,
    parsedAdditionalKeywords,
    parsedAdditionalKeywords1,
    parsedAdditionalKeywords2,
    parsedAdditionalKeywords3,
    parsedExcludedKeywords,
  ];
};
