import { Button, Input, Select } from 'shared/theme/elements';
import { Card, Row, Col, Space, Modal, Spin, UploadFile } from 'antd';
import React, { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { IWebsiteGarden, IWebsiteGardenCreatePayload, IWebsiteGardenForm } from 'shared/interfaces/IWebsiteGarden';
import { IUpload } from 'shared/interfaces/utils/IUpload';
import { useState } from 'react';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { DefaultOptionType } from 'antd/lib/select';
import { isEqual } from 'lodash';
import EditUserDetail from 'components/EditUserDetail/EditUserDetail';
import BulkUpload from './BulkUpload';
import { BulkUploadDarkGray, BulkUploadTextGray } from '../List/WebsiteGarden.styled';
import { ClassificationSelectOptions } from './AddWebsiteContainer';

const { confirm } = Modal;

interface Props {
  isEdit: boolean;
  isLoading?: boolean;
  websitegarden?: IWebsiteGarden | any;
  websiteGardenSubmit: (values: IWebsiteGardenCreatePayload, logo: IUpload) => void;
  countriesOptions: DefaultOptionType[];
  countrySearchInput: string;
  setCountrySearchInput: any;
  isCountryLoading?: boolean;
  options: ClassificationSelectOptions;
  //
  setCount: number | any;
}

const AddWebsiteView = ({
  isEdit,
  websiteGardenSubmit,
  websitegarden,
  countriesOptions,
  setCountrySearchInput,
  isCountryLoading,
  options,
  //
  setCount,
}: Props) => {
  const { push, location } = useHistory();
  const locationState = location.state as any;
  const [logo, setLogo] = useState<IUpload>(null);

  // DISABLE ALL INPUT FIELDS IF THERES A DATA IN INPUT FIELD
  const [isDisabled, setIsDisabled] = useState(false);

  // WEBSITE GARDEN FORM INITIAL VALUES
  const initialValues: IWebsiteGardenForm = {
    website_url: websitegarden ? websitegarden.website_url : '',
    country: websitegarden ? websitegarden.country : 'PHILIPPINES',
    website_name: websitegarden ? websitegarden.website_name : '',
    main_interest: websitegarden ? websitegarden.main_interest : '',
    focus: websitegarden ? websitegarden.focus : '',
    website_category: websitegarden ? websitegarden.website_category : '',
    isDisabled: false,
  };

  // WEBSITE GARDEN SCHEMA VALIDATION

  const websiteGardenValidationSchema: yup.SchemaOf<IWebsiteGardenForm> = yup.object().shape({
    website_url: yup.string().when('isDisabled', {
      is: false,
      then: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!',
        )
        .required('Website URL is required'),
    }),
    country: yup.string().when('isDisabled', {
      is: false,
      then: yup.string().required('Country is required'),
    }),
    website_name: yup.string().nullable(),
    main_interest: yup.string().nullable(),
    focus: yup.string().nullable(),
    website_category: yup.string(),
    isDisabled: yup.boolean().notRequired(),
  });

  // Change text handle on Modal if Add or Update
  const addOrUpdate = () => {
    const changeTitle = isEdit ? 'Update Website Entry' : 'Add Website Entry';
    const changeContent = isEdit
      ? 'Are you sure you want to Update this Website Entry?'
      : 'Are you sure you want to Add this Website Entry?';

    return { changeTitle, changeContent };
  };

  const { changeTitle, changeContent } = addOrUpdate();

  // WEBSITE GARDEN FORM

  const form: FormikProps<IWebsiteGardenForm> = useFormik<IWebsiteGardenForm>({
    initialValues,
    onSubmit: (values) => {
      let finalValues = { ...values };
      if (websitegarden) {
        finalValues = {
          ...values,
        };
      }
      confirm({
        centered: true,
        title: changeTitle,
        content: changeContent,
        onOk: async () => websiteGardenSubmit(finalValues, logo),
        okButtonProps: {
          size: 'large',
          style: { borderRadius: '4px', fontSize: '14px' },
        },
        cancelButtonProps: {
          size: 'large',
          style: { borderRadius: '4px', fontSize: '14px' },
        },
        okText: 'Yes',
      });
    },
    validationSchema: websiteGardenValidationSchema,
    enableReinitialize: true,
  });

  const goBack = () => {
    push(ROUTES.WEBSITEGARDEN.LIST.LIST, {
      filter: locationState?.filter,
      page: locationState?.page,
    });
  };

  const handleOnCancel = () => {
    goBack();
  };

  // Handle ADD REMOVE FILE TO TOGGLE INPUT AND UPLOAD FIELD
  const handleFile = (file: UploadFile | any) => {
    setLogo(file);
    setIsDisabled(true);
    form.resetForm();
    form.setFieldValue('isDisabled', true);
  };

  const handleRemoveFile = () => {
    // setLogo((prev: any[]) => prev.filter((f) => f.uid !== file.uid));
    setLogo(null);
    setIsDisabled(!isDisabled);
    form.setFieldValue('isDisabled', false);
  };

  const handleReaderLoad = (result: string | ArrayBuffer | null) => {
    const fileCount = Number(result?.toString().split(/\r\n|\r|\n/).length);

    setCount(fileCount - 2);
  };

  return (
    <>
      {!isEdit && (
        <>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card title="Website Bulk Upload Entry">
              <BulkUploadTextGray>
                Upload CSV file here with columns for{' '}
                <BulkUploadDarkGray>
                  Homepage URL - Publication Name - Country - Main Interest - Focus - Classifcation
                </BulkUploadDarkGray>
              </BulkUploadTextGray>
              <Row gutter={[16, 36]}>
                <Col xs={24} lg={12} xl={8}>
                  <BulkUpload
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFile}
                    disabled={isEqual(form.values, !form.initialValues)}
                    // disabled={
                    //   form.values.isDisabled === false
                    //     ? !isEqual(form.values, form.initialValues)
                    //     : !form.values.isDisabled
                    // }
                    onRemove={handleRemoveFile}
                    onReaderLoad={handleReaderLoad}
                  ></BulkUpload>
                </Col>
              </Row>
            </Card>
          </Space>
          <br />
        </>
      )}
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Card title="Website Single Entry">
          <Row gutter={[16, 36]}>
            {/* {isEdit && (
              <Col span={12}>
                <EditUserDetail label="Created by" date={websitegarden.date_created}></EditUserDetail>
              </Col>
            )} */}

            {isEdit && (
              <Col span={12}>
                <EditUserDetail
                  label="Modified by"
                  date={websitegarden?.date_updated}
                  // name={getUserName(author?.data?.updated_by?.[0])}
                ></EditUserDetail>
              </Col>
            )}

            <Col xs={24}>
              {/* Homepage URL */}
              <Input
                type="text"
                placeholder="Paste url here"
                id="website_url"
                name="website_url"
                label="Homepage URL"
                size="middle"
                value={form.values.website_url}
                onChange={form.handleChange}
                errorMessage={getErrorMessage(form.touched.website_url, form.errors.website_url)}
                $fullWidth
                isRequired
                disabled={isDisabled}
                autoComplete="off"
                // disabled={author.fetching || author.loading}
              ></Input>
            </Col>

            <Col xs={24} lg={12} xl={8}>
              {/* Publication Name */}
              <Input
                type="text"
                placeholder="Search Publication"
                id="website_name"
                name="website_name"
                label="Publication Name"
                size="middle"
                value={form.values.website_name}
                onChange={form.handleChange}
                disabled={isDisabled}
                autoComplete="off"
                // errorMessage={getErrorMessage(form.touched.author_name, form.errors.author_name)}
                $fullWidth
                // disabled={author.fetching || author.loading}
              ></Input>
            </Col>

            <Col xs={24} xl={8}>
              {/* Country */}
              <Select
                label="Country"
                placeHolder="Search Country"
                size="middle"
                id="country"
                value={form.values.country ? form.values.country : undefined}
                options={countriesOptions}
                onChange={(value) => form.setFieldValue('country', value)}
                errorMessage={getErrorMessage(form.touched.country, form.errors.country)}
                // disabled={author.fetching || author.loading}
                onSearch={(value) => setCountrySearchInput(value as string)}
                onClear={() => {
                  form.setFieldTouched('country', true);
                }}
                filterOption={false}
                showSearch
                allowClear
                autoClearSearchValue
                loading={isCountryLoading}
                notFoundContent={isCountryLoading ? <Spin size="small" /> : null}
                isRequired
                $fullWidth
                disabled={isDisabled}
              ></Select>
            </Col>

            <Col xs={24} lg={12} xl={8}>
              {/* Main Interest*/}
              <Input
                type="text"
                placeholder="Enter Main Interest"
                id="main_interest"
                name="main_interest"
                label="Main Interest"
                size="middle"
                value={form.values.main_interest}
                onChange={form.handleChange}
                // errorMessage={getErrorMessage(form.touched.author_name, form.errors.author_name)}
                $fullWidth
                disabled={isDisabled}
                // disabled={author.fetching || author.loading}
              ></Input>
            </Col>

            <Col xs={24} lg={12} xl={8}>
              {/* Focus */}
              <Input
                type="text"
                placeholder="Enter Focus"
                id="focus"
                name="focus"
                label="Focus"
                size="middle"
                value={form.values.focus}
                onChange={form.handleChange}
                disabled={isDisabled}
                // errorMessage={getErrorMessage(form.touched.author_name, form.errors.author_name)}
                $fullWidth

                // disabled={author.fetching || author.loading}
              ></Input>
            </Col>

            <Col xs={24} lg={12} xl={8}>
              {/* Classifications*/}
              <Select
                placeHolder="Search Classifications"
                id="website_category"
                label="Classifications"
                size="middle"
                options={options?.website_category}
                value={form.values.website_category}
                onChange={(value) => form.setFieldValue('website_category', value)}
                $fullWidth
                disabled={isDisabled}
                allowClear
                //
              ></Select>
            </Col>
          </Row>
        </Card>

        <Space size="middle">
          {/* <Button variant="outlined" onClick={() => handleOnCancel()} disabled={author.fetching || author.loading}>
          Cancel
        </Button> */}
          <Button variant="outlined" onClick={() => handleOnCancel()}>
            Cancel
          </Button>
          <Button
            disabled={isEqual(form.values, form.initialValues) && !logo}
            variant="primary"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {isEdit ? 'Update' : 'Add Website'}
            {/* Add Website */}
          </Button>
        </Space>
      </Space>
    </>
  );
};

export default AddWebsiteView;
