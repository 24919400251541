import { Spin } from 'antd';
import { lazy, Suspense } from 'react';

const ListPage = lazy(() => import('./ListContainer'));

export const PrintAdsList = () => {
  return (
    <Suspense fallback={<Spin />}>
      <ListPage />
    </Suspense>
  );
};
