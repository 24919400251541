import React from 'react';

import { InputLabel, LabelWrapper, RequiredLabel } from '../Input/Input.styled';
import { CDatePicker, DatePickerWrapper, ErrorText } from './DatePicker.styled';

interface Props {
  label?: string;
  isRequired?: boolean;
  $fullWidth?: boolean;
  errorMessage?: string;
  [key: string]: any;
}

const DatePicker = ({ label, isRequired, $fullWidth, errorMessage, ...datePickerProps }: Props) => {
  return (
    <DatePickerWrapper $isFullWidth={$fullWidth} $haserror={!!errorMessage}>
      {label && (
        <LabelWrapper>
          {isRequired && <RequiredLabel>*</RequiredLabel>}
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
      )}
      <CDatePicker status={errorMessage ? 'error' : ''} {...datePickerProps} />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </DatePickerWrapper>
  );
};

export default DatePicker;
