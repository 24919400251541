import { Col, Row, Tag } from 'antd';
import { Button, Card } from 'shared/theme/elements';
import styled from 'styled-components';

export const KeywordWrapper = styled.div``;
export const KeywordCard = styled(Card)``;
export const RequiredLabel = styled.label`
  color: ${({ theme }) => theme.errorColor};
`;
export const ErrorMessage = styled.label`
  display: block;
  color: ${({ theme }) => theme.errorColor};
`;

export const Label = styled.label``;

export const FieldRow = styled(Row)`
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
`;
export const FieldCol = styled(Col)`
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 8px 16px;
`;

export const StyledTag = styled(Tag)`
  padding: 8px 14px;
  font-size: 14px;
  .anticon {
    margin-left: 12px;
  }
`;

export const FieldsButtonContainer = styled.div`
  background-color: #f5f5f5;
`;
export const FieldsButton = styled(Button)`
  background-color: #f5f5f5;
  color: #434343;
  border: none;
  border-radius: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: #f5f5f5;
    color: #434343;
    border: none;
  }
`;

export const checkStyleMW = {
  display: 'grid',
  gridTemplateRows: '0.75fr 0.75fr 0.75fr 1fr',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  gap: '0px',
  marginTop: '20px',
};
export const checkStyle2 = {
  display: 'flex',
  gap: '24px',
  marginTop: '20px',
};

export const IconContainer = styled.div`
  .anticon {
    font-size: 18px;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      color: rgba(60, 60, 60);
    }
  }
`;
