import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  body {
    line-height: 1.4;
    background-color: #F7F9FC;
  }
  
  .ant-table {
    border: 1px solid #f0f0f0;
  }

  .ant-menu-submenu-popup {
    .ant-menu-inline.ant-menu-sub {
      background: #1f243a;
    }
    .ant-menu-item-selected {
      background: white !important;
      color: ${theme.primaryColor} !important;
      &:hover {
        color: #46485a;
      }
    }
  }

  .active-col {
    background-color: #EEF6FF !important;
  }
  
    /* Selector to get style icon color when custom topic and website garden is selected */
    li.ant-menu-item.ant-menu-item-selected > svg{
    color: ${theme.primaryColor} !important;
  }

  .ant-modal-footer {
    display: flex;
  }
`;
