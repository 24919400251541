import { Row, Tag } from 'antd';
import React, { useMemo } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Props {
  type: any[];
  iterateData: any;
  tagColor: string;
  handleRemoveKeyword: (value: any, type: any) => void;
}

const AdditionalKeywordsTagDisplay = ({ type, iterateData, tagColor, handleRemoveKeyword }: Props) => {
  const display_data = useMemo(() => {
    if (type[0] === 'AdditionalMain') return iterateData.map((item) => item.replace('11', '').trim());
    if (type[0] === 'AddAdditional') return iterateData.map((item) => item.replace('21', '').trim());
    if (type[0] === 'AddExcluded') return iterateData.map((item) => item.replace('31', '').trim());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterateData]);

  return (
    <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
      {display_data?.map((value: any) => (
        <Tag
          color={tagColor}
          closable
          closeIcon={
            <CloseCircleOutlined
              style={{
                marginLeft: 4,
                color: tagColor,
                fontSize: 15,
              }}
            />
          }
          style={{
            padding: 8,
            fontSize: 15,
          }}
          key={value}
          onClose={(e) => {
            e.preventDefault();
            handleRemoveKeyword(value, type[0]);
          }}
        >
          {value}
        </Tag>
      ))}
    </Row>
  );
};

export default AdditionalKeywordsTagDisplay;
