import generatePicker from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import styled from 'styled-components';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = DatePicker;

export const CRangePicker = styled(RangePicker)``;

CRangePicker.defaultProps = {
  size: 'large',
};

export const DatePickerWrapper = styled.div<{
  $haserror?: boolean;
  $isFullWidth?: boolean;
}>`
  height: fit-content;

  > ${CRangePicker} {
    &.ant-picker-range {
      width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
    }
    /* .ant-picker {
      width: '100%';
    } */
  }
`;
