interface Props {
  current_user_role: string[];
}

export const parent_access_tv = ({ current_user_role }: Props) => {
  return current_user_role.includes('access_tv')
    ? 'access_tv'
    : current_user_role.includes('access_tv_program')
    ? 'access_tv_program'
    : current_user_role.includes('access_automation_tv_setup')
    ? 'access_automation_tv_setup'
    : '';
};

// new add
export const parent_access_radio = ({ current_user_role }: Props) => {
  return current_user_role.includes('access_radio')
    ? 'access_radio'
    : current_user_role.includes('access_automation_radio_setup')
    ? 'access_automation_radio_setup'
    : '';
};

export const parent_access_admin = ({ current_user_role }: Props) => {
  return current_user_role.includes('access_admin_users_management')
    ? 'access_admin_users_management'
    : current_user_role.includes('access_clients')
    ? 'access_clients'
    : current_user_role.includes('access_mailer_setup')
    ? 'access_mailer_setup'
    : current_user_role.includes('access_corpus')
    ? 'access_corpus'
    : '';
};

export const parent_access_mailer_monitoring = ({ current_user_role }: Props) => {
  return current_user_role.includes('access_mailer_monitoring')
    ? 'access_mailer_monitoring'
    : current_user_role.includes('access_corpus')
    ? 'access_corpus'
    : current_user_role.includes('access_fb_download_monitoring')
    ? 'access_fb_download_monitoring'
    : '';
};
