import React from 'react';
import { Row, Col, Checkbox } from 'antd';

interface Props {
  passedType: string;
  checkedValue: any;
  iterateValues: any;
  focus_Keywords: (type: string, value: any) => void;
}

const DisplayFocusKeywords = ({ passedType, checkedValue, iterateValues, focus_Keywords }: Props) => {
  return (
    <Checkbox.Group onChange={(e: any) => focus_Keywords(passedType, e)} value={checkedValue}>
      <Row gutter={[16, 16]}>
        {iterateValues?.map((value: any) => (
          <Col span={iterateValues.length <= 2 ? 12 : 8} key={value}>
            <Checkbox key={value} value={value}>
              {value}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default DisplayFocusKeywords;
