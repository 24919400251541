import { Card, Col, Row, Space, Spin, Modal } from 'antd';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { IInfluencerForm, IInfluencers } from 'shared/interfaces/IInfluencers';
import { Button, Input, Select } from 'shared/theme/elements';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import {
  AvatarLabel,
  PosterProfileContainer,
  RadioContainer,
  RadioLabel,
  RadioVerifiedContainer,
} from './AddInfluencer.styled';
import {
  InfluencerPageSearch,
  InfluencerPageSearchLoading,
  InfluencerPageSearchOptions,
} from './AddInfluencerContainer';
import AddInfluencerInput from './helpers/AddInfluencerInput';

import { Radio as AddInfluencerRadio } from 'shared/theme/elements/Radio';
import InputTextArea from 'shared/theme/elements/Input/InputTextArea';
import AddInfluencerUpload from './helpers/AddInfluencerUpload';
import { toTitleCase } from 'shared/utils/toTitleCase';
import { useEffect } from 'react';

const { confirm } = Modal;

interface Props {
  form: FormikProps<IInfluencerForm>;
  influencer: {
    loading: boolean;
    fetching: boolean;
    data: IInfluencers | undefined;
  };
  //
  onSearch: (key: keyof InfluencerPageSearch, value: any) => void;
  searchOptions: InfluencerPageSearchOptions;
  searchLoading: InfluencerPageSearchLoading;
  //
  avatarUrl?: string | any;
  setAvatar: React.Dispatch<
    React.SetStateAction<{
      url?: string | undefined;
      file?: File | undefined;
    }>
  >;
  //
  isEdit: boolean;
  //
  isGoingToVerify?: boolean;
  //
}

const AddInfluencerView = ({
  form,
  influencer,
  setAvatar,
  searchOptions,
  searchLoading,
  onSearch,
  isEdit,
}: //
// avatarUrl,
//
Props) => {
  //
  const { push, location } = useHistory();
  const locationState = location.state as any;

  // const [verifyTrue, setVerifyTrue] = useState<string>('Verified');

  //HANDLE SOCIAL MEDIA PLATFORM CHANGE
  const handlePlatformChange = (val: string) => {
    form.setFieldValue('social_poster_source', val.toLowerCase());
  };

  // HANDLE VERIFIED / UNVERIFIED CHANGE
  const handleVerifyChange = (val: string) => {
    form.setFieldValue('social_poster_verified', val === 'Verified' ? 'True' : 'False');
  };

  // Handle on Drop Image then fetch URL
  const handleOnDrop = (url: string) => {
    form.setFieldValue('social_poster_profile_banner', url);
  };

  // Handle Upload
  const handleUploadAvatar = (file: any) => {
    // form.setFieldValue('social_poster_profile_banner', url);
    setAvatar({
      file,
      // url,
    });
  };

  // //
  const goBack = () => {
    push(ROUTES.INFLUENCERS.LIST.LIST, {
      filter: locationState?.filter,
      page: locationState?.page,
    });
  };

  const handleOnCancel = () => {
    if (!isEqual(form.values, form.initialValues)) {
      confirm({
        centered: true,
        title: "You've made some changes",
        content: 'Are you sure you want to discard your changes?',
        onOk: () => goBack(),
        okButtonProps: {
          size: 'large',
          style: { borderRadius: '4px', fontSize: '14px' },
        },
        cancelButtonProps: {
          size: 'large',
          style: { borderRadius: '4px', fontSize: '14px' },
        },
        okText: 'Yes',
      });

      return;
    }

    goBack();
  };
  // //

  if (isEdit) {
    useEffect(() => {
      if (locationState?.verify) {
        setTimeout(() => {
          form.setFieldValue('social_poster_verified', 'True');
        }, 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }

  useEffect(() => {
    if (form.values.social_poster_source !== 'youtube') {
      setTimeout(() => {
        form.setFieldValue('social_poster_views_count', 0);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.social_poster_source]);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title="Details">
        <Row gutter={[16, 36]}>
          <Col sm={9} md={12} xl={12} xxl={12}>
            {/*  */}
            <RadioContainer>
              <RadioLabel>Select Social Media Platform</RadioLabel>
              <AddInfluencerRadio
                items={['Facebook', 'Twitter', 'Instagram', 'Youtube', 'Tiktok']}
                type={'radio'}
                btnStyle={'solid'}
                $fullWidth
                defaultValue={influencer.data ? toTitleCase(influencer.data?.social_poster_source) : 'Twitter'}
                onChange={(val) => handlePlatformChange(val)}
                disabled={isEdit}
              />
            </RadioContainer>
            {/*  */}
          </Col>

          <Col xs={24}>
            {/* Avatar */}
            <AvatarLabel>Add Avatar</AvatarLabel>
            <AddInfluencerUpload
              disabled={influencer.fetching || influencer.loading}
              imageSrc={
                influencer.data?.social_poster_profile_banner
                  ? influencer.data?.social_poster_profile_banner
                  : influencer.data?.social_poster_profile_img
                  ? influencer.data?.social_poster_profile_img
                  : undefined
              }
              onChange={handleUploadAvatar}
              accept="image/png, image/jpg, image/jpeg"
              onDrop={handleOnDrop}
            ></AddInfluencerUpload>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Full Name */}
            <Input
              isRequired
              type="text"
              placeholder="Enter name here"
              id="social_poster_name"
              name="social_poster_name"
              label="Influencer's Full Name"
              size="middle"
              value={form.values.social_poster_name}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(form.touched.social_poster_name, form.errors.social_poster_name)}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></Input>
          </Col>

          <Col xs={24} xl={8}>
            {/* Country */}
            <Select
              label="Country"
              placeHolder="Search Country"
              size="middle"
              id="social_poster_country"
              value={form.values.social_poster_country ? form.values.social_poster_country : undefined}
              options={searchOptions.country}
              onChange={(value) => form.setFieldValue('social_poster_country', value)}
              errorMessage={getErrorMessage(form.touched.social_poster_country, form.errors.social_poster_country)}
              onSearch={(value) => onSearch('country', value)}
              onClear={() => {
                form.setFieldTouched('country', true);
              }}
              filterOption={false}
              showSearch
              allowClear
              autoClearSearchValue
              loading={searchLoading.country}
              notFoundContent={searchLoading.country ? <Spin size="small" /> : null}
              isRequired
              $fullWidth
            ></Select>
          </Col>

          <Col xs={24} xl={8}></Col>

          {/*//////////////////////////////////////  */}

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Content Count*/}
            <AddInfluencerInput
              isRequired
              type="number"
              placeholder="0"
              id="social_poster_content_count"
              name="social_poster_content_count"
              label="Content Count"
              size="middle"
              value={form.values.social_poster_content_count}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_content_count,
                form.errors.social_poster_content_count,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Follower Count */}
            <AddInfluencerInput
              isRequired
              type="number"
              placeholder="0"
              id="social_poster_follower_count"
              name="social_poster_follower_count"
              label="Follower Count"
              size="middle"
              value={form.values.social_poster_follower_count}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_follower_count,
                form.errors.social_poster_follower_count,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Following Count */}
            <AddInfluencerInput
              isRequired
              type="number"
              placeholder="0"
              id="social_poster_following_count"
              name="social_poster_following_count"
              label="Following Count"
              size="middle"
              value={form.values.social_poster_following_count}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_following_count,
                form.errors.social_poster_following_count,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Likes Count */}
            <AddInfluencerInput
              isRequired
              type="number"
              placeholder="0"
              id="social_poster_likes_count"
              name="social_poster_likes_count"
              label="Likes Count"
              size="middle"
              value={form.values.social_poster_likes_count}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_likes_count,
                form.errors.social_poster_likes_count,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          {/* DUPLICATE */}

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Views Count | IF YOUTUBE Chosen Platform */}
            {form.values.social_poster_source === 'youtube' && (
              <AddInfluencerInput
                isRequired
                type="number"
                placeholder="0"
                id="social_poster_views_count"
                name="social_poster_views_count"
                label="Views Count"
                size="middle"
                value={form.values.social_poster_views_count}
                onChange={form.handleChange}
                autoComplete="off"
                errorMessage={getErrorMessage(
                  form.touched.social_poster_views_count,
                  form.errors.social_poster_views_count,
                )}
                $fullWidth
                disabled={form.values.social_poster_source !== 'youtube'}
              ></AddInfluencerInput>
            )}
          </Col>

          {/* DUPLICATE */}

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Following Count */}
          </Col>

          {/* /////// */}

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Profile URL*/}
            <AddInfluencerInput
              // isRequired
              type="text"
              placeholder="Enter URL here"
              id="social_poster_profile_url"
              name="social_poster_profile_url"
              label="Profile URL"
              size="middle"
              value={form.values.social_poster_profile_url}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_profile_url,
                form.errors.social_poster_profile_url,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Profile Username*/}
            <AddInfluencerInput
              isRequired
              type="text"
              placeholder="Enter Username here"
              id="social_poster_username"
              name="social_poster_username"
              label="Profile Username"
              size="middle"
              value={form.values.social_poster_username}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(form.touched.social_poster_username, form.errors.social_poster_username)}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Influencer Profile IMG*/}
            <AddInfluencerInput
              isRequired
              type="text"
              placeholder="Enter URL here"
              id="social_poster_profile_img"
              name="social_poster_profile_img"
              label="Profile Img"
              size="middle"
              value={form.values.social_poster_profile_img}
              onChange={form.handleChange}
              autoComplete="off"
              errorMessage={getErrorMessage(
                form.touched.social_poster_profile_img,
                form.errors.social_poster_profile_img,
              )}
              $fullWidth
              disabled={influencer.fetching || influencer.loading}
            ></AddInfluencerInput>
          </Col>

          {/* VERIFIED / UNVERIFIED */}

          {/*  */}
          {!isEdit && (
            <Col sm={9} md={12} xl={12} xxl={12}>
              <RadioVerifiedContainer>
                <AddInfluencerRadio
                  label="Verified"
                  isRequired
                  items={['Verified', 'Unverified']}
                  type={'radio'}
                  btnStyle={'solid'}
                  $fullWidth
                  defaultValue={form.values.social_poster_verified === 'False' ? 'Unverified' : 'Verified'}
                  // onChange={(val) => (isEdit ? handleChange : handleVerifyChange(val))}
                  onChange={(val) => handleVerifyChange(val)}
                />
              </RadioVerifiedContainer>
            </Col>
          )}

          {/* Poster Profile */}
          <Col xs={24}>
            <PosterProfileContainer>
              {/*  */}
              <InputTextArea
                isRequired
                label="Poster Profile"
                id="social_poster_profile"
                name="social_poster_profile"
                onChange={form.handleChange}
                rows={4}
                autoSize={{ minRows: 3, maxRows: 5 }}
                value={form.values.social_poster_profile}
                errorMessage={getErrorMessage(form.touched.social_poster_profile, form.errors.social_poster_profile)}
                $fullWidth
              />
            </PosterProfileContainer>
          </Col>

          {/* ////// */}

          <Space size="middle">
            <Button
              variant="outlined"
              onClick={() => handleOnCancel()}
              disabled={influencer.fetching || influencer.loading}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                form.handleSubmit();
              }}
            >
              {influencer.data && influencer.data.social_poster_verified !== 'False'
                ? 'Update'
                : influencer.data && influencer.data.social_poster_verified === 'False'
                ? 'Verify'
                : 'Save'}
            </Button>
          </Space>
        </Row>
      </Card>
    </Space>
  );
};

export default AddInfluencerView;
