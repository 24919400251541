import React, { useState } from 'react';
import { Segmented, Space, Table, TablePaginationConfig, Button, Input } from 'antd';
import { BUCKETS_LIST_COLUMNS } from './TableColumns';
import { BucketWrapper, BucketCard, BucketTableContainer, BucketHeader } from './Bucket.styled';
import DeleteBucket from './Components/DeleteBucket';
import CreateCategories from '../../Categories/Components/CreateCategories';
import CopyBuckets from './Components/CopyBuckets';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { ExpandedTable } from './ExpandedTable';
import { IClientSubscription } from 'shared/interfaces/IClientSubscription';
import { isAfter } from 'date-fns';

const { Search } = Input;

// const platformOptions = [
//   {
//     label: 'Mediawatch',
//     value: 'mediawatch',
//   },
//   {
//     label: 'Sharedview',
//     value: 'sharedview',
//   },
//   {
//     label: 'Adwatch',
//     value: 'adwatch',
//   },
// ];

interface Props {
  data: any;
  deleteForm: any;
  loading?: boolean;
  page: number;
  pageSize: number;
  pageTotal: any;
  handlePageChange: any;
  handlePageSizeChange: any;
  categoryForm: any;
  copyForm: any;
  setPlatform: React.Dispatch<React.SetStateAction<string>>;
  setCategoryName: React.Dispatch<React.SetStateAction<string>>;
  clientID: any;
  platform: any;
  categoryName: any;
  categories: any;
  categoriesLoading?: boolean;
  setCategoryID: React.Dispatch<React.SetStateAction<any[]>>;
  BUCKETS_ID: any;
  bucketFromListID: string;
  bucketsCompleteData: any;
  handleDeleteKeyword: (keywordID: string, bucketID: string) => void;
  setBucketName: any;
  subscriptionData?: IClientSubscription;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setkeywordID: React.Dispatch<React.SetStateAction<string[]>>;
  keywordPage: number;
  keywordPageTotal: any;
  keywordHandlePageChange: any;
  keywordLoading: boolean;
}
const ListView = ({
  data,
  deleteForm,
  loading,
  page,
  pageSize,
  pageTotal,
  handlePageChange,
  handlePageSizeChange,
  categoryForm,
  copyForm,
  setPlatform,
  setCategoryName,
  clientID,
  platform,
  categoryName,
  categories,
  categoriesLoading,
  setCategoryID,
  BUCKETS_ID,
  bucketFromListID,
  bucketsCompleteData,
  handleDeleteKeyword,
  setBucketName,
  subscriptionData,
  setLoading,
  setkeywordID,
  keywordPage,
  keywordPageTotal,
  keywordHandlePageChange,
  keywordLoading,
}: Props) => {
  const platformOptions = [
    {
      label: 'Mediawatch',
      value: 'mediawatch',
      disabled:
        subscriptionData?.plan_details_mediawatch?.status === 'active' &&
        isAfter(new Date(), new Date(subscriptionData?.plan_details_mediawatch?.pkg_date_end || null)),
    },
    {
      label: 'Sharedview',
      value: 'sharedview',
      disabled:
        subscriptionData?.plan_details_sharedview?.status === 'active' &&
        isAfter(new Date(), new Date(subscriptionData?.plan_details_sharedview?.pkg_date_end || null)),
    },
    {
      label: 'Adwatch',
      value: 'adwatch',
      disabled:
        subscriptionData?.plan_details_adwatch?.status === 'active' &&
        isAfter(new Date(), new Date(subscriptionData?.plan_details_adwatch?.pkg_date_end || null)),
    },
  ];

  const [category_modal, setCategoryModal] = useState<boolean>(false);
  const { push } = useHistory();
  const [open_modal, set_open_modal] = useState<{
    copy: boolean;
    delete: boolean;
    copy_to_other_platform: boolean;
  }>({
    copy: false,
    delete: false,
    copy_to_other_platform: false,
  });

  const handleOk = (action: string) => {
    if (action === 'create') {
      setCategoryModal(false);
      categoryForm.handleSubmit();
      setLoading(true);
    } else if (action === 'delete') {
      set_open_modal({ ...open_modal, ['delete']: false });
      deleteForm.handleSubmit();
      setLoading(true);
    } else if (action === 'copy_to_other_platforms') {
      set_open_modal({ ...open_modal, ['copy_to_other_platform']: false });
      copyForm.handleSubmit();
      setLoading(true);
    }
  };

  const handleCancel = (action: string) => {
    if (action === 'create') {
      setCategoryModal(false);
    } else if (action === 'delete') {
      set_open_modal({ ...open_modal, ['delete']: false });
    } else if (action === 'copy_to_other_platforms') {
      set_open_modal({ ...open_modal, ['copy_to_other_platform']: false });
    } else {
      set_open_modal({ ...open_modal, ['copy']: false });
    }
  };

  const deleteBuckets = (id: string, bucket_name: string) => {
    set_open_modal({
      ...open_modal,
      ['delete']: true,
    });
    deleteForm.setFieldValue('id', id);
    deleteForm.setFieldValue('bucket_name', bucket_name);
  };

  const handleOnCopy = (platform: string, id: string) => {
    copyForm.setFieldValue('platform', platform);
    copyForm.setFieldValue('bucket_id', id);
    set_open_modal({ ...open_modal, ['copy_to_other_platform']: true });
  };

  const update_bucket = (id: string, keyword?: string, keyword_id?: string) => {
    push(ROUTES.ADMIN.CLIENTS.BUCKETS.UPDATE, {
      category_name: categoryName,
      client_id: clientID,
      type: !!keyword ? 'keyword' : 'update',
      platform: platform,
      bucket_id: id,
      //
      buckets: BUCKETS_ID,
      _id: bucketFromListID,
      buckets_all: bucketsCompleteData,
      comingFromViewBucketList: true,
      addKeyword: !!keyword,
      keyword_id: keyword_id,
    });
  };

  const create_bucket = () => {
    push(ROUTES.ADMIN.CLIENTS.BUCKETS.ADD, {
      client_id: clientID,
      type: 'create-btn',
      platform: platform,
      //
      buckets: BUCKETS_ID,
      _id: bucketFromListID,
      buckets_all: bucketsCompleteData,
      comingFromViewBucketList: true,
      category_name: categoryName,
    });
  };

  const [expandableID, setExpandableID] = useState<string[]>([]);

  return (
    <BucketWrapper>
      <BucketCard>
        <BucketHeader>
          <Segmented
            defaultValue={platform}
            options={platformOptions}
            onChange={(value: any) => {
              setPlatform(value);
            }}
          />
          <Space size="middle">
            <Search
              enterButton="Search"
              placeholder="Search Bucket Name"
              onSearch={setBucketName}
              style={{ width: '300px' }}
              allowClear
            />
            <Button type="primary" onClick={() => setCategoryModal(true)}>
              Create Category
            </Button>
            <Button type="primary" onClick={() => create_bucket()}>
              Create Bucket
            </Button>
          </Space>
        </BucketHeader>
        <BucketTableContainer>
          <Table
            columns={BUCKETS_LIST_COLUMNS({
              deleteBuckets,
              handleOnCopy,
              update_bucket,
              subscriptionData,
              setLoading,
            })}
            dataSource={data}
            rowKey="_id"
            loading={loading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: pageTotal,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results`,
              position: ['bottomRight', 'topRight'],
            }}
            onChange={({ current, pageSize }: TablePaginationConfig) => {
              handlePageChange(current || 1);
              handlePageSizeChange(pageSize || 10);
            }}
            expandable={{
              expandedRowKeys: expandableID,
              expandedRowRender: (record) =>
                ExpandedTable(
                  record,
                  handleDeleteKeyword,
                  update_bucket,
                  keywordPage,
                  keywordPageTotal,
                  keywordHandlePageChange,
                  keywordLoading,
                ),
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandableID([record.key]);
                  keywordHandlePageChange(1);
                } else {
                  setExpandableID([]);
                }
                setkeywordID(record?.boolean_keywords_id?.map(({ _id }) => _id));
              },
            }}
          />
        </BucketTableContainer>
      </BucketCard>

      <CreateCategories
        setLoading={setLoading}
        openModal={category_modal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        create_category={categoryForm}
        placeholder={'Category Name'}
      />
      <DeleteBucket
        isModalOpen={open_modal.delete}
        handleOk={handleOk}
        onCancel={handleCancel}
        deleteForm={deleteForm}
      />
      <CopyBuckets
        copyForm={copyForm}
        categories={categories}
        categoriesLoading={categoriesLoading}
        modalOpen={open_modal.copy_to_other_platform}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setCategoryName={setCategoryName}
        setCategoryID={setCategoryID}
        categoryName={categoryName}
      />
    </BucketWrapper>
  );
};

export default ListView;
