import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useWebsiteGardenService } from 'shared/services/websiteGardenService';
import AddWebsiteView from './AddWebsiteView';
import { IWebsiteGardenCreatePayload, IWebsiteGardenUpdatePayload } from 'shared/interfaces/IWebsiteGarden';
import { ROUTES } from 'shared/constants/ROUTES';
import { Alert, Spinner } from 'shared/theme/elements';
import { useCountryService } from 'shared/services/countryService';
import { DefaultOptionType } from 'antd/lib/select';
import { transformPayload } from 'shared/utils/transformPayload';
import { useDebounce } from 'use-debounce';
import { IUpload } from 'shared/interfaces/utils/IUpload';
import { CLASSIFICATIONS_FILTER } from 'shared/constants/WEBSITE_GARDEN_SORT';

export interface ClassificationSelectOptions {
  website_category?: DefaultOptionType[];
}

const AddWebsiteContainer = () => {
  // SERVICE
  const { getSingleWebsiteGarden, createWebsiteGarden, createBulkUpload, updateWebsiteGarden } =
    useWebsiteGardenService();

  const { location, push } = useHistory();
  const locationState = location.state as any;
  const { state } = location as any;
  const user = localStorage?.getItem('user_admin') ? JSON.parse(localStorage.getItem('user_admin') || '') : null;
  const { createMutation } = createWebsiteGarden();
  const { createBulkUploadMutation } = createBulkUpload();
  const { updateMutation } = updateWebsiteGarden();
  const queryClient = useQueryClient();

  // COUNTRY
  const [countrySearchInput, setCountrySearchInput] = useState<string>('');
  const [countryKeyword] = useDebounce(countrySearchInput, 800);

  // Website Garden ID
  const WEBSITE_GARDEN_ID = useMemo(() => {
    if (locationState?.id) return locationState.id;

    return undefined;
  }, [locationState]);

  // Get Single Website Garden Service
  const { data: websiteGardenData, isLoading: websiteGardenIsLoading } = getSingleWebsiteGarden(state?.id);

  // <== Country Service
  const { getCountries } = useCountryService();
  const { data: countriesData, isLoading: isCountryLoading } = getCountries({
    page: 1,
    limit: 10,
    country_name: countryKeyword,
  });

  // Booleans
  const isEdit = useMemo(() => (WEBSITE_GARDEN_ID ? true : false), [WEBSITE_GARDEN_ID]);

  const goBack = () => {
    push(ROUTES.WEBSITEGARDEN.LIST.LIST, {
      filter: locationState?.filter,
      page: locationState?.page,
    });
  };

  // Count Number of Files Uploaded
  const [count, setCount] = useState<number | any>(0);

  const websiteGardenSubmit = (values: IWebsiteGardenCreatePayload | IWebsiteGardenUpdatePayload, file?: IUpload) => {
    return new Promise((resolve, reject) => {
      const payload: IWebsiteGardenCreatePayload | IWebsiteGardenUpdatePayload = {
        ...values,
        _id: WEBSITE_GARDEN_ID ? WEBSITE_GARDEN_ID : undefined,
        updated_by: user?._id,
        created_by: user?._id,
      };
      const transformedPayload = transformPayload(payload);

      const formData = new FormData();
      formData.append('token', transformedPayload);

      if (file) {
        formData.append('csv_file', file);

        // UPLOAD File Logic

        createBulkUploadMutation.mutate(formData, {
          onSuccess: () => {
            resolve(true);
            Alert.success(`${count} Websites are successfully added!`);
            queryClient.invalidateQueries('website-Garden');
            push(ROUTES.WEBSITEGARDEN.LIST.LIST, {
              filter: locationState?.filter,
              page: locationState?.page,
            });
          },
          onSettled: () => {
            (async () => await queryClient.refetchQueries('website-garden'))();
          },
          onError: (error: any) => {
            reject(error);
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
            } else {
              Alert.error('Something Went Wrong');
            }
          },
        });
      }

      if (isEdit) {
        updateMutation.mutateAsync(formData, {
          onSuccess: () => {
            resolve(true);
            Alert.success('', 'Website Garden Has Been Successfully Updated');
            queryClient.invalidateQueries('website-garden');
            goBack();
          },
          onSettled: () => {
            (async () => await queryClient.refetchQueries('website-garden'))();
          },
          onError: (error: any) => {
            reject(error);
            if (error?.response?.data?.server_response) {
              Alert.error('', error?.response?.data?.server_response);
            } else {
              Alert.error('', 'Failed to Update Website Garden');
            }

            return;
          },
        });
      } else if (!file) {
        createMutation.mutate(formData, {
          onSuccess: () => {
            resolve(true);
            Alert.success('1 Website Single Entry Successfully Added!');
            queryClient.invalidateQueries('website-Garden');
            push(ROUTES.WEBSITEGARDEN.LIST.LIST, {
              filter: locationState?.filter,
              page: locationState?.page,
            });
          },
          onSettled: () => {
            (async () => await queryClient.refetchQueries('website-garden'))();
          },
          onError: (error: any) => {
            reject(error);
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
            } else {
              Alert.error('Something Went Wrong');
            }
          },
        });
      }
    });
  };

  // Country Options
  const countriesOptions = useMemo(() => {
    const results = countriesData?.data ? countriesData.data : [];
    return results.map((country) => {
      return {
        value: country.country_name,
        label: country.country_name,
      };
    });
  }, [countriesData]) as DefaultOptionType[];

  // Classifications Options
  const classificationsOptions: ClassificationSelectOptions | any = useMemo(
    () => ({
      website_category: CLASSIFICATIONS_FILTER.map(({ id, value }) => ({ label: id, value: value })),
    }),
    [],
  );

  const isLoading = useMemo(() => {
    return websiteGardenIsLoading;
  }, [websiteGardenIsLoading]);

  // console.log('the count now', count);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <AddWebsiteView
          websitegarden={websiteGardenData || []}
          websiteGardenSubmit={websiteGardenSubmit}
          isEdit={isEdit}
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          countriesOptions={countriesOptions}
          options={classificationsOptions}
          countrySearchInput={countrySearchInput}
          setCountrySearchInput={setCountrySearchInput}
          isCountryLoading={isCountryLoading}
          setCount={setCount}
        />
      )}
    </>
  );
};

export default AddWebsiteContainer;
