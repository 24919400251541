import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICategoryRequestPayload, ICategoryResponsePayload } from 'shared/interfaces/ICategory';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET, POST, DELETE } = useAxios();
export const useCategoryDao = () => {
  const getCategories = async (payload?: ICategoryRequestPayload) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET<ICategoryResponsePayload>({
      url: `${ENDPOINTS.CATEGORY}/show`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  const getAllCategories = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET<any>({
      url: ENDPOINTS.CATEGORY,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const createCategory = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const formData = new FormData();
    formData.append('token', transformedPayload);
    const response = await POST<any>({
      url: `${ENDPOINTS.CATEGORY}/create`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const updateCategory = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const formData = new FormData();
    formData.append('token', transformedPayload);
    const response = await POST<any>({
      url: `${ENDPOINTS.CATEGORY}/update`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };

  const removeCategory = async (payload?: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await DELETE({
      url: `${ENDPOINTS.CATEGORY}/delete`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  return {
    getCategories,
    createCategory,
    removeCategory,
    updateCategory,
    getAllCategories,
  };
};
