import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  option: {
    label: string;
    path?: string | undefined;
  };
}

const MailerBreadCrumb = ({ option }: Props) => {
  const location = useLocation();
  const locationState = location.state as any;

  return (
    <Link
      to={{
        pathname: `${option.path || ''}`,
        state: {
          id: locationState?.client_id,
          client_name: locationState?.client_name,
        },
      }}
    >
      {option.label}
    </Link>
  );
};

export default MailerBreadCrumb;
