import styled from 'styled-components';

export const WebsiteGardenViewContainer = styled.div`
  width: 100%;
`;

export const WebsiteGardenContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const WebsiteGardenink = styled.span`
  cursor: pointer;
  color: #40a9ff;
`;

export const BulkUploadTextGray = styled.p`
  margin-bottom: 0.5rem;
  color: #8c8c8c;
`;

export const BulkUploadDarkGray = styled.span`
  color: #434343;
`;
