import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { useBucketService } from 'shared/services/bucketService';
import { useKeywordService } from 'shared/services/keywordService';
import { useFormik } from 'formik';
import { Alert } from 'shared/theme/elements';
import { useQueryClient } from 'react-query';
import { useCategoryService } from 'shared/services/categoryService';
import { BreadcrumbContext } from 'shared/context/Breadcrumb';
import { Modal } from 'antd';
import { useClientSubscriptionService } from 'shared/services/clientSubscriptionService';
import useLoading from 'shared/hooks/useLoading';
import { SpinnerWrapper } from 'components/SpinnerWrapper';
import ListView from './ListView';

function Container() {
  const { state } = useLocation();
  const locationState = state as any;
  const queryClient = useQueryClient();

  const [platform, set_Platform] = useState<string>(locationState?.platform);

  const user = localStorage?.getItem('user_admin') ? JSON.parse(localStorage.getItem('user_admin') || '') : null;
  const [categoryName, setCategoryName] = useState<string>('');

  // CONTEXT
  const { setAdditionalValue } = useContext(BreadcrumbContext);

  // CUSTOM LOADING HOOK
  const { loading, setLoading } = useLoading();

  const category_name = locationState?.category_name;
  // const CATEGORY_ID = locationState?._id;
  const [categoryID, setCategoryID] = useState<any[]>([]);
  // const BUCKETS_ID = locationState?.buckets.map((bucket: any) => bucket._id);
  const BUCKETS_ID = locationState?.buckets.map((bucket: any) => bucket._id) || [];
  // Check buckets ID on location.state if undefined
  const BUCKETS_ID_UNDEFINED = Object.values(BUCKETS_ID).includes(undefined);
  const { page, pageSize, handlePageChange, handlePageSizeChange } = usePagination();
  const { page: keywordPage, handlePageChange: keywordHandlePageChange } = usePagination();

  // This is to get the ID based from the bucket List
  const bucketFromListID = locationState._id;
  const bucketsCompleteData = locationState?.buckets;

  const { getBuckets, updateBucket, deleteBucket } = useBucketService();
  const { delete_bucket_mutation } = deleteBucket();
  const { getKeywordShow } = useKeywordService();
  const { createCategory, getCategories, getAllCategories, getMultipleClientCategories, updateCategory } =
    useCategoryService();
  const { createCategoryMutation } = createCategory();
  const { updateCategoryMutation } = updateCategory();
  const { mutate: mutateUpdateBucket } = updateBucket();
  const { getClientSubscription } = useClientSubscriptionService();

  const { data: subscription } = getClientSubscription({
    client_obj_id: locationState?.client_id,
  });

  const subscriptionData = useMemo(() => subscription?.data[0], [subscription?.data]);

  const { data: category_datas } = getCategories({
    client_obj_id: locationState?.client_id,
    platform: platform,
  });

  const [bucketName, setBucketName] = useState('');

  const { data: bucket_data, isLoading: buckets_loading } = getBuckets({
    bucket_name: bucketName,
    buckets_id: !BUCKETS_ID_UNDEFINED ? BUCKETS_ID : locationState?.buckets,
    page: page,
    pageSize: pageSize,
    platform: platform,
  });

  const get_buckets_data = useMemo(() => {
    if (!bucket_data?.data) {
      return [];
    }

    return bucket_data.data.map((bucket) => {
      if (!bucket.effectivity_start && !bucket.effectivity_end) {
        let effectivity_start;
        let effectivity_end;
        const effectivity_status = true;

        switch (bucket.platform) {
          case 'mediawatch':
            effectivity_start = subscriptionData?.plan_details_mediawatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_mediawatch.billing_end;
            break;
          case 'adwatch':
            effectivity_start = subscriptionData?.plan_details_adwatch.billing_start;
            effectivity_end = subscriptionData?.plan_details_adwatch.billing_end;
            break;
          default:
            effectivity_start = subscriptionData?.plan_details_sharedview.billing_start;
            effectivity_end = subscriptionData?.plan_details_sharedview.billing_end;
        }

        return {
          ...bucket,
          effectivity_start,
          effectivity_end,
          effectivity_status,
        };
      }

      return bucket;
    });
  }, [bucket_data?.data, subscriptionData]);

  // const keywordsID =
  //   get_buckets_data.length &&
  //   get_buckets_data.map((keywords: any) => keywords.boolean_keywords_id.map((keyword: any) => keyword._id)).flat();

  const [keywordID, setkeywordID] = useState<string[]>([]);

  const { data: keywords_data, isLoading: keywords_data_loading } = getKeywordShow({
    keywords_id: keywordID,
    page: keywordPage,
    limit: 5,
  });

  const modify_category_id_list = () => {
    const results: any[] = [];
    for (let i = 0; i < categoryID.length; i++) {
      results.push({
        _id: categoryID[i],
      });
    }

    return results;
  };
  const multiple_categories = getMultipleClientCategories(modify_category_id_list());

  const get_multiple_categories_data = useMemo(() => {
    const results: any[] = [];
    if (multiple_categories.length) {
      multiple_categories.map((values: any) => {
        const data = values?.data?.data?.[0];
        results.push(data);
      });

      return results;
    } else {
      return [];
    }
  }, [multiple_categories]);

  const get_keywords_data = useMemo(() => {
    const keywords: any[] = [];
    keywords_data?.data.map((value: any) => {
      keywords.push(value.keywords);
    });

    return keywords;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords_data]);

  const get_bucket_keywords_data = useMemo(() => {
    return get_buckets_data?.map((value: any) => {
      const booleanKeywords = value.boolean_keywords_id.map(({ _id }) => _id);
      const bucket_keywords = keywords_data?.data.filter(({ _id }) => booleanKeywords.includes(_id));

      return {
        _id: value._id,
        bucket_name: value.bucket_name,
        // buckets_count: value.buckets_count,
        buckets_count: value.keywords_count,
        keywords: bucket_keywords,
        date_created: value.date_created,
        effectivity_start: value.effectivity_start,
        effectivity_end: value.effectivity_end,
        effectivity_status: value.effectivity_status,
        boolean_keywords_id: value.boolean_keywords_id,
        index: value._id,
        key: value._id,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_buckets_data, get_keywords_data]);

  const bucket_delete = useFormik({
    initialValues: {
      id: '',
      bucket_name: '',
    },
    onSubmit: (values) => {
      delete_bucket_mutation.mutate(
        {
          _id: values.id,
        },
        {
          onSuccess: () => {
            Alert.success('Bucket deleted');
            queryClient.invalidateQueries('bucket');
            setLoading(false);
          },
          onError: (error: any) => {
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
              setLoading(false);
            } else {
              Alert.error('Something Went Wrong');
              setLoading(false);
            }
          },
        },
      );
    },
  });

  const create_category = useFormik({
    initialValues: {
      category_name: '',
    },
    onSubmit: (values, { resetForm }) => {
      createCategoryMutation.mutate(
        {
          client_obj_id: locationState?.client_id,
          category_name: values.category_name,
          platform: platform,
          buckets: [],
          created_by: user._id,
          order_num: category_datas?.meta?.pagination.total ? category_datas?.meta?.pagination.total + 1 : 0,
        },
        {
          onSuccess: () => {
            Alert.success('Category created');
            queryClient.invalidateQueries(['category'], {
              refetchInactive: true,
            });
            resetForm();
            setLoading(false);
          },
          onError: (error: any) => {
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
              setLoading(false);
            } else {
              Alert.error('Something Went Wrong');
              setLoading(false);
            }
          },
        },
      );
    },
  });

  const copy_to_other_values = {
    bucket_id: '',
    category_ids: undefined,
    platform: '',
  };

  const copy_to_Platforms_form = useFormik({
    initialValues: copy_to_other_values,
    onSubmit: (values) => {
      const bucketCopy: any[] = [];
      get_multiple_categories_data.forEach((datas: any) => {
        datas?.buckets?.map((bucket: any) => {
          bucketCopy.push({
            _id: bucket._id,
          });
        });

        bucketCopy.push({ _id: values.bucket_id });
        updateCategoryMutation.mutate(
          {
            _id: datas?._id,
            category_name: datas?.category_name,
            platform: datas.platform,
            buckets: bucketCopy,
            updated_by: user._id,
            // client_obj_id: user.client_id,
            client_obj_id: locationState?.client_id,
            order_num: datas?.order_num,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['bucket'], {
                refetchInactive: true,
              });

              queryClient.invalidateQueries(['category'], {
                refetchInactive: true,
              });
              Alert.success(`Bucket successfully Copied to ${datas?.category_name}`);
              setLoading(false);
            },
            onError: (error: any) => {
              if (error?.response?.data?.server_response) {
                Alert.error(error.response.data.server_response);
                setLoading(false);
              } else {
                Alert.error('Something Went Wrong');
                setLoading(false);
              }
            },
          },
        );
      });
    },
  });

  const { data: all_category_datas, isLoading: categoriesLoading } = getAllCategories({
    client_obj_id: locationState?.client_id,
    category_name: categoryName,
    platform: copy_to_Platforms_form.values.platform.toLowerCase(),
  });

  const get_all_category_datas = all_category_datas?.data || [];

  // // ADD CATEGORY NAME ON BREADCRUMB LIST
  useEffect(() => {
    setAdditionalValue(category_name);

    return () => {
      setAdditionalValue(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_name]);

  const executeDeleteKeyword = (keywordID: string, bucketID: string) => {
    const bucketData = get_buckets_data.filter(({ _id }) => bucketID === _id);

    mutateUpdateBucket(
      {
        ...bucketData[0],
        client_obj_id: locationState?.client_id,
        boolean_keywords: bucketData[0].boolean_keywords_id.filter(({ _id }) => _id !== keywordID),
        updated_by: user._id,
      },
      {
        onSuccess: () => {
          Alert.success('Keyword Deleted');
          queryClient.invalidateQueries(['category'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['bucket'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['keywords'], {
            refetchInactive: true,
          });
          setLoading(false);
        },
        onError: (error: any) => {
          if (error?.response?.data?.server_response) {
            Alert.error(error.response.data.server_response);
            setLoading(false);
          } else {
            Alert.error('Something Went Wrong');
            setLoading(false);
          }
        },
      },
    );
  };

  const handleDeleteKeyword = (keywordID: string, bucketID: string) => {
    Modal.confirm({
      centered: true,
      title: 'Delete Keyword',
      content: 'Are you sure you want to delete this keyword?',
      onOk: async () => {
        await executeDeleteKeyword(keywordID, bucketID);
        setLoading(true);
      },
      okButtonProps: {
        danger: true,
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      cancelButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      okText: 'Delete',
    });
  };

  // ON PAGE LOAD SET TO FALSE FIRST TO STOP LOADING
  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinnerWrapper spinning={loading} position="top">
      <ListView
        data={get_bucket_keywords_data}
        deleteForm={bucket_delete}
        loading={buckets_loading}
        page={page}
        pageSize={pageSize}
        pageTotal={bucket_data?.meta?.pagination.total || 0}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        categoryForm={create_category}
        copyForm={copy_to_Platforms_form}
        setPlatform={set_Platform}
        setCategoryName={setCategoryName}
        clientID={locationState?.client_id}
        platform={locationState?.platform}
        categoryName={category_name}
        categories={get_all_category_datas}
        categoriesLoading={categoriesLoading}
        setCategoryID={setCategoryID}
        //
        BUCKETS_ID={BUCKETS_ID}
        bucketFromListID={bucketFromListID}
        bucketsCompleteData={bucketsCompleteData}
        handleDeleteKeyword={handleDeleteKeyword}
        setBucketName={setBucketName}
        subscriptionData={subscriptionData}
        setLoading={setLoading}
        setkeywordID={setkeywordID}
        keywordPage={keywordPage}
        keywordPageTotal={keywords_data?.meta?.pagination.total || 0}
        keywordHandlePageChange={keywordHandlePageChange}
        keywordLoading={keywords_data_loading}
      />
    </SpinnerWrapper>
  );
}

export default Container;
