import { useMutation, useQuery } from 'react-query';
import { useUsersDao } from 'shared/dao/usersDao';
import {
  IUsersCreateForm,
  IUsersRequestPayload,
  IUsersResponsePayload,
  IUsersUpdateForm,
} from 'shared/interfaces/IUsers';

const {
  getUsers: getUsersDao,
  getSingleUser: getSingleUserDao,
  createUser: createUserDao,
  updateUser: updateUserDao,
  deleteUser: deleteUserDao,
  get_users_by_client: get_users_by_clientDao,
  getUserHashKey: getUserHashKeyDao,
  //
  searchUsers: searchUsersDao,
} = useUsersDao();

export const useUsersService = () => {
  //
  const getUsers = (payload?: IUsersRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      IUsersResponsePayload,
      Error
    >(['admin-user-all', payload], () => getUsersDao(payload));
    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  const searchUsers = (payload?: IUsersRequestPayload) => {
    const { isLoading, data } = useQuery<IUsersResponsePayload, Error>(['users-fullname', payload], () =>
      searchUsersDao(payload),
    );

    return {
      data,
      isLoading,
    };
  };

  const getSingleUser = (id?: string) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery<IUsersResponsePayload, Error>(
      ['admin-user-single', id],
      () => getSingleUserDao(id || ''),
      { enabled: !!id },
    );

    return {
      data: data?.data,
      // data: data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
    };
  };

  const createUser = () => {
    const createMutation = useMutation((payload?: { data?: IUsersCreateForm }) => createUserDao(payload));

    return {
      createMutation,
    };
  };

  const updateUser = () => {
    const updateMutation = useMutation((payload: { data?: IUsersUpdateForm }) => updateUserDao(payload));

    return {
      updateMutation,
    };
  };

  const deleteUser = () => {
    const deleteMutation = useMutation((id: string) => deleteUserDao(id));

    return {
      deleteMutation,
    };
  };

  const get_users_by_client = (payload?: any) => {
    return useQuery<any, Error>(['client_users', payload], () => get_users_by_clientDao(payload));
  };

  const getUserHashKey = (payload?: any) => {
    return useQuery<any, Error>(['hash_keys', payload], () => getUserHashKeyDao(payload), { enabled: !!payload._id });
  };

  //
  return {
    getUsers,
    get_users_by_client,
    getUserHashKey,
    getSingleUser,
    createUser,
    updateUser,
    deleteUser,
    searchUsers,
  };
};
