import React, { useCallback, useState } from 'react';

import { SidebarWrapper, CollapseButton } from './Sidebar.styled';
import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';
import SidebarProfile from './SidebarProfile';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

const Sidebar = () => {
  const [collapsed, setIsCollapse] = useState(false);
  const toggleCollapse = useCallback(() => {
    setIsCollapse((collapsed) => !collapsed);
  }, []);

  return (
    <SidebarWrapper collapsed={collapsed} breakpoint="xl" onBreakpoint={(e) => setIsCollapse(e)}>
      <SidebarLogo collapsed={collapsed} />
      <SidebarProfile collapsed={collapsed} />
      <SidebarMenu collapsed={collapsed} />
      {/* <CollapseIcon onClick={toggleCollapse} /> */}

      <CollapseButton onClick={() => toggleCollapse()}>
        {collapsed ? (
          <DoubleRightOutlined style={{ color: 'white' }} />
        ) : (
          <DoubleLeftOutlined style={{ color: 'white' }} />
        )}
      </CollapseButton>
    </SidebarWrapper>
  );
};

export default Sidebar;
