import { Card } from 'shared/theme/elements';
import styled from 'styled-components';

export const BucketWrapper = styled.div``;

export const BucketCard = styled(Card)``;

export const BucketTableContainer = styled.div`
  margin-top: 2rem;
`;

export const BucketHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
