import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  type: number;
  addKeywords: any[];
  onClickType: string[];
  onChange: (type: string) => void;
  disabled: boolean;
}

const AddAdditionalKeywordsButton = ({ type, addKeywords, onClickType, disabled, onChange }: Props) => {
  return (
    <Button
      size="large"
      type="dashed"
      style={{
        width: '100%',
        height: 180,
        backgroundColor: '#fafafa',
      }}
      onClick={() => onChange(onClickType[type])}
      disabled={disabled || addKeywords[type].length === 1 ? true : false}
    >
      <PlusOutlined style={{ marginBottom: 10 }} />
      <p>Add Additional Keywords</p>
    </Button>
  );
};

export default AddAdditionalKeywordsButton;
