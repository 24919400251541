import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { clientSubscriptionDao } from 'shared/dao/clientSubscription';
import {
  IClientSubscriptionRequestPayload,
  IClientSubscriptionResponsePayload,
} from 'shared/interfaces/IClientSubscription';

const { getClientSubscription: getClientSubscriptionDao, updateClientSubscription: updateClientSubscriptionDao } =
  clientSubscriptionDao();
export const useClientSubscriptionService = () => {
  const getClientSubscription = (payload: IClientSubscriptionRequestPayload) => {
    return useQuery<IClientSubscriptionResponsePayload, AxiosError>(['clientSubscription', payload], () =>
      getClientSubscriptionDao(payload),
    );
  };

  const updateClientSubscription = () => {
    const update_subscription_mutation = useMutation((payload?: any) => updateClientSubscriptionDao(payload));
    return {
      update_subscription_mutation,
    };
  };

  return {
    getClientSubscription,
    updateClientSubscription,
  };
};
