import React from 'react';

const AsyncMailerPage = React.lazy(() => import('./MailerContainer'));

export const MailerViewerPage = () => {
  return (
    <React.Suspense fallback={null}>
      <AsyncMailerPage />
    </React.Suspense>
  );
};
