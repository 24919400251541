import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET, POST, DELETE } = useAxios();
export const bucketDao = () => {
  const getBuckets = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET<any>({
      url: `${ENDPOINTS.BUCKET}/show`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const updateBucket = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const formData = new FormData();
    formData.append('token', transformedPayload);
    const response = await POST<any>({
      url: `${ENDPOINTS.BUCKET}/update`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const deleteBucket = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await DELETE({
      url: `${ENDPOINTS.BUCKET}/delete`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  return {
    getBuckets,
    updateBucket,
    deleteBucket,
  };
};
