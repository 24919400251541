import { Input, Modal } from 'antd';

interface Props {
  openModal: boolean;
  handleOk: (action: string) => void;
  handleCancel: (action: string) => void;
  create_category: any;
  placeholder?: string;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateCategories = ({ openModal, handleOk, handleCancel, create_category, placeholder, setLoading }: Props) => {
  return (
    <>
      <Modal
        title="Create New Category"
        onOk={() => {
          if (setLoading) {
            handleOk('create');
            setLoading(true);
          } else {
            handleOk('create');
          }
        }}
        onCancel={() => handleCancel('create')}
        okText="Create"
        open={openModal}
      >
        <Input
          style={{
            width: '100%',
          }}
          placeholder={placeholder}
          value={create_category.values.category_name}
          onChange={(e) => create_category.setFieldValue('category_name', e.target.value)}
        />
      </Modal>
    </>
  );
};

export default CreateCategories;
