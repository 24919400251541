import sign from 'jwt-encode';
import { CONFIG } from 'shared/config/config';
import { IUser } from 'shared/interfaces/IAuth';
import { ls } from './ls';
import jwtDecode from 'jwt-decode';

export const transformPayload = <T>(payload?: T, customAccessToken?: string): string => {
  const user = ls.get('user_admin') as IUser;
  const data = {
    ...payload,
    access_token: customAccessToken ? customAccessToken : user?.token,
  };
  const jwt = sign(data, CONFIG.ACCESS_SECRET);
  return jwt;
};

export function decodeToken<T>(token: string): T {
  return jwtDecode(token);
}
