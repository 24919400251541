import { useMutation, useQueries, useQuery } from 'react-query';
import { useInfluencersDao } from 'shared/dao/influencersDao';
import {
  IInfluencerCreatePayload,
  IInfluencerExportPayload,
  IInfluencerUpdatePayload,
  InfluencersRequestPayload,
  InfluencersResponsePayload,
  SocialInfluencerRequestPayload,
} from 'shared/interfaces/IInfluencers';

const {
  getInfluencers: getInfluencersDao,
  getSocialInfluencers: getSocialInfluencersDao,
  getSingleInfluencer: getSingleInfluencerDao,
  createInfluencer: createInfluencerDao,
  updateInfluencer: updateInfluencerDao,
  deleteInfluencer: deleteInfluencerDao,
  getSharedviewInfluencers: getSharedviewInfluencersDao,
  exportInfluencers: exportInfluencersDao,
} = useInfluencersDao();

export const useInfluencersService = () => {
  const getInfluencers = (payload?: InfluencersRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      InfluencersResponsePayload,
      Error
    >(['influencers', payload], () => getInfluencersDao(payload));
    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  const getSocialInfluencers = (payload?: SocialInfluencerRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      InfluencersResponsePayload,
      Error
    >(['social-influencers', payload], () => getSocialInfluencersDao(payload));
    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  //

  const getSingleInfluencer = (id?: string) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery<InfluencersResponsePayload, Error>(
      ['single-influencer', id],
      () => getSingleInfluencerDao(id || ''),
      { enabled: !!id },
    );

    return {
      data: data?.data,
      // data: data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
    };
  };

  const exportInfluencers = () => {
    const exportMutation = useMutation((payload?: IInfluencerExportPayload) => exportInfluencersDao(payload));

    return {
      exportMutation,
    };
  };

  const createInfluencer = () => {
    const createMutation = useMutation((payload?: { data?: IInfluencerCreatePayload; avatar?: File }) =>
      createInfluencerDao(payload),
    );

    return {
      createMutation,
    };
  };

  const updateInfluencer = () => {
    const updateMutation = useMutation((payload: { data?: IInfluencerUpdatePayload; avatar?: File }) =>
      updateInfluencerDao(payload),
    );

    return {
      updateMutation,
    };
  };

  const deleteInfluencer = () => {
    const deleteMutation = useMutation((id: string) => deleteInfluencerDao(id));

    return {
      deleteMutation,
    };
  };
  const getSharedviewInfluencers = (payload?: any) => {
    return useQuery<any, Error>(['allInfluencers', payload], () => getSharedviewInfluencersDao(payload));
  };

  const getMultipleInfluencers = (payload: any[]) => {
    return useQueries<any[]>(
      payload.map((p) => {
        return {
          queryKey: ['influencersIDs', p._id],
          queryFn: () => getSingleInfluencerDao(p._id),
        };
      }),
    );
  };

  return {
    getInfluencers,
    getSocialInfluencers,
    getSingleInfluencer,
    createInfluencer,
    updateInfluencer,
    deleteInfluencer,
    getSharedviewInfluencers,
    getMultipleInfluencers,
    exportInfluencers,
  };
};
