import { useMutation, useQuery } from 'react-query';
import { useWebsiteGardenDao } from 'shared/dao/websiteGardenDao';
import {
  // IWebsiteGarden,
  // IWebsiteGardenCreatePayload,
  IWebsiteGardenRequestPayload,
  IWebsiteGardenResponsePayload,
  // IWebsiteGardenUpdatePayload,
  // IWebsiteGardenUpdatePayload,
} from 'shared/interfaces/IWebsiteGarden';

const {
  getWebsiteGarden: getWebsiteGardenDao,
  getSingleWebsiteGarden: getSingleWebsiteGardenDao,
  deleteWebsiteGarden: deleteWebsiteGardenDao,
  createWebsiteGarden: createWebsiteGardenDao,
  updateWebsiteGarden: updateWebsiteGardenDao,
  createBulkUpload: createBulkUploadDao,
} = useWebsiteGardenDao();

export const useWebsiteGardenService = () => {
  const getWebsiteGarden = (page: number, payload?: IWebsiteGardenRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      IWebsiteGardenResponsePayload,
      Error
    >(['website-garden', page, payload], () => getWebsiteGardenDao(page, payload));
    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  const getSingleWebsiteGarden = (id?: string) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery<
      IWebsiteGardenResponsePayload,
      Error
    >(['website-garden', id], () => getSingleWebsiteGardenDao(id || ''), { enabled: !!id });

    return {
      data: data?.data,
      // data: data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
    };
  };

  const createWebsiteGarden = () => {
    const createMutation = useMutation((formData: FormData) => createWebsiteGardenDao(formData));

    return {
      createMutation,
    };
  };

  const createBulkUpload = () => {
    const createBulkUploadMutation = useMutation((formData: FormData) => createBulkUploadDao(formData));

    return {
      createBulkUploadMutation,
    };
  };

  const updateWebsiteGarden = () => {
    const updateMutation = useMutation((formData: FormData) => updateWebsiteGardenDao(formData));
    return {
      updateMutation,
    };
  };

  const deleteWebsiteGarden = () => {
    const deleteMutation = useMutation((id: string) => deleteWebsiteGardenDao(id));

    return {
      deleteMutation,
    };
  };

  return {
    getWebsiteGarden,
    deleteWebsiteGarden,
    createWebsiteGarden,
    createBulkUpload,
    updateWebsiteGarden,
    getSingleWebsiteGarden,
  };
};
