export interface IKeywordListProps {
  keywords: string[];
  additionalKeywords: string[];
  additionalKeywords1: string[];
  additionalKeywords2: string[];
  additionalKeywords3: string[];
  excludedKeywords: string[];
}

const stringMapper = (str: string[]) => str.map((s) => `"${s}"`);
export const categoryKeywordsToBoolean = ({
  keywords,
  additionalKeywords,
  additionalKeywords1,
  additionalKeywords2,
  additionalKeywords3,
  excludedKeywords,
}: IKeywordListProps) => {
  let booleanKeyword = '';
  let booleanAdditionalKeyword = '';
  let booleanAdditionalKeyword1 = '';
  let booleanAdditionalKeyword2 = '';
  let booleanAdditionalKeyword3 = '';
  let booleanExcludedKeyword = '';
  if (keywords.length > 0) {
    const mappedKeyword = stringMapper(keywords);
    booleanKeyword = `(${mappedKeyword.join('|')})`;
  }
  if (additionalKeywords.length > 0) {
    booleanAdditionalKeyword = `(${stringMapper(additionalKeywords).join('|')})`;
  }

  if (additionalKeywords1.length > 0) {
    booleanAdditionalKeyword1 = `(${stringMapper(additionalKeywords1).join('|')})`;
  }

  if (additionalKeywords2.length > 0) {
    booleanAdditionalKeyword2 = `(${stringMapper(additionalKeywords2).join('|')})`;
  }

  if (additionalKeywords3.length > 0) {
    booleanAdditionalKeyword3 = `(${stringMapper(additionalKeywords3).join('|')})`;
  }
  if (excludedKeywords.length > 0) {
    booleanExcludedKeyword = `(${stringMapper(excludedKeywords).join('|')})`;
  }

  return `${booleanKeyword}${additionalKeywords.length > 0 ? ` ${booleanAdditionalKeyword}` : ''}${
    additionalKeywords1.length > 0 ? ` ${booleanAdditionalKeyword1}` : ''
  }${additionalKeywords2.length > 0 ? ` ${booleanAdditionalKeyword2}` : ''}${
    additionalKeywords3.length > 0 ? ` ${booleanAdditionalKeyword3}` : ''
  }${excludedKeywords.length > 0 ? ` -${booleanExcludedKeyword}` : ''}`;
};
