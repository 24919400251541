import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IInfluencerCreatePayload,
  IInfluencerExportPayload,
  IInfluencerUpdatePayload,
  InfluencersRequestPayload,
  InfluencersResponsePayload,
  SocialInfluencerRequestPayload,
} from 'shared/interfaces/IInfluencers';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET, POST, PUT, DELETE } = useAxios();

// const { GET, DELETE } = useAxios();

export const useInfluencersDao = () => {
  const getInfluencers = async (payload?: InfluencersRequestPayload) => {
    const transformedPayload = transformPayload<InfluencersRequestPayload>(payload);

    const response = await GET<InfluencersResponsePayload>({
      url: `${ENDPOINTS.INFLUENCERS}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  // FOR SOCIAL MEDIA POST SEARCH
  const getSocialInfluencers = async (payload?: SocialInfluencerRequestPayload) => {
    const transformedPayload = transformPayload<SocialInfluencerRequestPayload>(payload);

    const response = await GET<InfluencersResponsePayload>({
      url: `${ENDPOINTS.INFLUENCERS}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  //
  const getSingleInfluencer = async (id?: string) => {
    const transformedPayload = transformPayload({ _id: id, limit: 1 });

    const response = await GET<InfluencersResponsePayload>({
      url: `${ENDPOINTS.INFLUENCERS}/show`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const createInfluencer = async (payload?: { data?: IInfluencerCreatePayload; avatar?: File }) => {
    const transformedPayload = transformPayload<IInfluencerCreatePayload>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);
    if (payload?.avatar) formData.append('social_poster_profile_banner', payload?.avatar);
    // if (payload?.avatar) formData.append('image', payload?.avatar);

    const response = await POST({
      url: `${ENDPOINTS.INFLUENCERS}/create`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const updateInfluencer = async (payload?: { data?: IInfluencerUpdatePayload; avatar?: File }) => {
    const transformedPayload = transformPayload<IInfluencerUpdatePayload>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);
    if (payload?.avatar) formData.append('image', payload?.avatar);

    const response = await PUT({
      url: `${ENDPOINTS.INFLUENCERS}/update`,
      data: formData,
      params: {
        token: formData.get('token'),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const deleteInfluencer = async (id: string) => {
    const transformedPayload = transformPayload({ _id: id });

    const response = await DELETE({
      url: `${ENDPOINTS.INFLUENCERS}/delete`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getSharedviewInfluencers = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET({
      url: `${ENDPOINTS.INFLUENCERS}`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const exportInfluencers = async (payload?: IInfluencerExportPayload) => {
    const transformedPayload = transformPayload(payload);

    const formData = new FormData();
    formData.append('token', transformedPayload);

    const response = await POST({
      url: `${ENDPOINTS.INFLUENCERS}/generate`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  return {
    getInfluencers,
    getSocialInfluencers,
    getSingleInfluencer,
    deleteInfluencer,
    createInfluencer,
    updateInfluencer,
    getSharedviewInfluencers,
    exportInfluencers,
  };
};
