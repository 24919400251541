import styled from 'styled-components';
import { Upload } from 'antd';

export const RadioLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const AvatarLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioVerifiedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioVerifiedLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const PosterProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PosterProfileLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const AddInfluencerUploadDragger = styled(Upload.Dragger)`
  /* position: relative; */
`;

export const AddInfluencerWrapper = styled.div<{
  $error?: boolean;
  $success?: boolean;
}>`
  // //

  .ant-upload.ant-upload-drag {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-color: ${({ $error, $success }) => ($error ? '#ff4d4f' : $success ? '#52c41a' : '#d9d9d9')};
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
  }
`;

export const AddInfluencerImg = styled.img`
  width: '100%';
`;
