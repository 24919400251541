import { InputLabel, LabelWrapper, RequiredLabel } from '../Input/Input.styled';
import { CTimePicker, ErrorText, TimePickerWrapper } from './TimePicker.styled';

type Props = {
  label?: string;
  isRequired?: boolean;
  $fullWidth?: boolean;
  errorMessage?: string;
  [key: string]: any;
};

const TimePicker = ({ label, isRequired, $fullWidth, errorMessage, ...timePickerProps }: Props) => {
  return (
    <TimePickerWrapper $isFullWidth={$fullWidth} $haserror={!!errorMessage}>
      {label && (
        <LabelWrapper>
          {isRequired && <RequiredLabel>*</RequiredLabel>}
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
      )}
      <CTimePicker {...timePickerProps} status={errorMessage ? 'error' : ''} />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </TimePickerWrapper>
  );
};

export default TimePicker;
