import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
// import { Upload as AntUpload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
// import { UploadWrapper } from 'shared/theme/elements/Upload/Upload.styled';
import { getBase64 } from 'shared/utils/getBase64';
import { AddInfluencerImg, AddInfluencerUploadDragger, AddInfluencerWrapper } from '../AddInfluencer.styled';

type Props = {
  onChange: (file: any, imageUrl: string) => void;
  onDrop: (imageUrl: string) => void;
  imageSrc?: string;
  accept?: string;
  disabled?: boolean;
  errorMessage?: string;
};

const AddInfluencerUpload: React.FC<Props> = ({ onDrop, onChange, imageSrc, accept, disabled }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const checkValidURL = /^(http|https):/;

  const success = (msg: string) => {
    message.success(msg, 5);
  };

  const error = (msg: string) => {
    message.error(msg, 5);
  };

  const handleChange = (info: UploadChangeParam<UploadFile<Blob>>) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    const imageBlog = info.file;

    getBase64(imageBlog, (imageUrl) => {
      setImageUrl(imageUrl);
      setIsLoading(false);
      onChange(imageBlog, imageUrl);
    });
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // FALLBACK IMAGE
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
  };

  // LOGIC TO GET URL ON DROP
  const handleOnDrop = (evt: {
    stopPropagation: () => void;
    preventDefault: () => void;
    dataTransfer: { getData: (arg0: string) => any };
  }) => {
    evt.stopPropagation();
    evt.preventDefault();
    const onDropImageUrl = evt.dataTransfer.getData('text/html');

    const rex = /src="?([^"\s]+)"?\s*/;
    const url: any = rex.exec(onDropImageUrl);

    // onDrop(url[1]);
    if (checkValidURL.test(url[1])) {
      onDrop(url[1]);
      setImageUrl(imageUrl);
      success(`File Successfully Uploaded`);
      // SET BORDER DASH COLOR TO GREEN
      setIsUploadError(false);
      setIsUploadSuccess(true);
    } else {
      // SET BORDER DASH COLOR TO RED
      setIsUploadError(true);
      setIsUploadSuccess(false);
      error(`Invalid File Format: Make sure that the complete URL with http or https is followed. Please try again.`);
    }
  };

  useEffect(() => {
    setImageUrl(imageSrc);
  }, [imageSrc]);

  return (
    <AddInfluencerWrapper $error={isUploadError} $success={isUploadSuccess}>
      <AddInfluencerUploadDragger
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        onChange={handleChange}
        onDrop={handleOnDrop}
        beforeUpload={() => false}
        accept={accept}
        disabled={disabled}
      >
        {imageUrl ? (
          <AddInfluencerImg src={imageUrl} onError={handleError} alt="avatar" style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </AddInfluencerUploadDragger>
    </AddInfluencerWrapper>
  );
};

export default AddInfluencerUpload;
