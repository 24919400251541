import { Row, Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Props {
  type: string;
  iterateData: any;
  tagColor: string;
  handleRemoveKeyword: (value: any, type: any) => void;
}
const DisplayTags = ({ type, iterateData, tagColor, handleRemoveKeyword }: Props) => {
  return (
    <Row gutter={[8, 8]} style={{ marginBottom: 15 }}>
      {iterateData?.map((value: any) => (
        <Tag
          color={tagColor}
          closable
          closeIcon={
            <CloseCircleOutlined
              style={{
                marginLeft: 4,
                color: '#ffffff',
                fontSize: 15,
              }}
            />
          }
          style={{
            padding: 9,
            fontSize: 15,
            whiteSpace: 'normal',
          }}
          key={value}
          onClose={(e) => {
            e.preventDefault();
            handleRemoveKeyword(value, type);
          }}
        >
          {value}
        </Tag>
      ))}
    </Row>
  );
};

export default DisplayTags;
