import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICountryRequestPayload, ICountryResponsePayload } from 'shared/interfaces/ICountry';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET } = useAxios();
export const useCountryDao = () => {
  const getCountries = async (payload?: ICountryRequestPayload) => {
    const transformedPayload = transformPayload<ICountryRequestPayload>(payload);
    const response = await GET<ICountryResponsePayload>({
      url: ENDPOINTS.COUNTRY,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };

  return {
    getCountries,
  };
};
