import { Image, Layout, Menu as AntMenu, Typography } from 'antd';
import { MdDoubleArrow } from 'react-icons/md';
import styled from 'styled-components';
import { theme } from 'shared/theme';

const { Sider } = Layout;

export const SidebarWrapper = styled(Sider)`
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.whiteColor};
  height: 100vh;

  .ant-layout-sider-children {
    height: inherit;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }

  .sidebar-menu-wrapper {
    height: 100%;
  }

  &.ant-layout-sider-collapsed {
    width: 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }
`;

export const SidebarLogoContainer = styled.div`
  height: 80px;
  display: grid;
  place-content: center;
  padding-inline: 0.875rem;
  overflow: hidden;

  svg {
    width: auto;
    height: 26px;
  }

  svg.without-text {
    width: auto;
    height: 30px;
  }
`;

// Profile
interface ProfileWrapperProps {
  isCollapsed: boolean;
}

export const ProfileWrapper = styled.div<ProfileWrapperProps>`
  display: flex;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'start')};
  align-items: center;
  gap: 0.625rem;
  padding-inline: 0.875rem;
`;

export const ProfileDetails = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

export const ProfileImage = styled(Image)`
  height: 36px;
  width: 36px;
  object-fit: contain;
  border-radius: 50%;
`;

export const ProfileNameText = styled(Typography.Paragraph)`
  font-weight: 500;
  color: white !important;
  font-size: 0.875rem;
  max-width: inherit;
  margin: 0 !important;
`;

export const ProfileEmailText = styled(Typography.Paragraph)`
  font-size: 0.625rem;
  color: white;
  margin: 0 !important;
`;

// Sidebar Menu
export const MenuContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 48px;
  overflow: auto;

  .main {
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px !important;
      background-color: #23273c;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #797b88;
    }
  }
`;

export const Menu = styled(AntMenu)`
  background: transparent !important;

  /* ant-menu-item ant-menu-item-selected */

  .ant-menu {
    &-submenu {
      .ant-menu-submenu-title {
      }
      .ant-menu-inline.ant-menu-sub {
        background: #1f243a;
      }
    }

    &-item.ant-menu-item-selected {
      background: white !important;

      span {
        color: ${theme.primaryColor} !important;
      }

      &:hover {
        color: #46485a;
      }
    }
  }

  .menu__logout {
    /* margin-top: 3rem; */
  }
`;

export const CollapseIcon = styled(MdDoubleArrow)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0.5rem 0;
`;

export const CollapseButton = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.darkColor};
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  position: absolute;
  bottom: 0;
`;
