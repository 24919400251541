import { useMutation, useQueries, useQuery } from 'react-query';
import { useCategoryDao } from 'shared/dao/categoryDao';
import { ICategoryRequestPayload, ICategoryResponsePayload } from 'shared/interfaces/ICategory';

const {
  getCategories: getCategoriesDao,
  createCategory: createCategoryDao,
  updateCategory: updateCategoryDao,
  removeCategory: removeCategoryDao,
  getAllCategories: getAllCategoriesDao,
} = useCategoryDao();
export const useCategoryService = () => {
  const getCategories = (payload?: ICategoryRequestPayload, enabled?: boolean) => {
    return useQuery<ICategoryResponsePayload, Error>(['category', payload], () => getCategoriesDao(payload), {
      enabled,
      cacheTime: 5000,
    });
  };

  const getAllCategories = (payload: any) => {
    return useQuery<any, Error>(['allCategory', payload], () => getAllCategoriesDao(payload));
  };

  const createCategory = () => {
    const createCategoryMutation = useMutation((payload?: any) => createCategoryDao(payload));
    return {
      createCategoryMutation,
    };
  };

  const updateCategory = () => {
    const updateCategoryMutation = useMutation((payload?: any) => updateCategoryDao(payload));
    return {
      updateCategoryMutation,
    };
  };

  const removeCategory = () => {
    const removeCategoryMutation = useMutation((payload?: any) => removeCategoryDao(payload));
    return {
      removeCategoryMutation,
    };
  };

  const getMultipleClientCategories = (payload: any[]) => {
    return useQueries<any[]>(
      payload.map((p) => {
        return {
          queryKey: ['categoryByID', p._id],
          queryFn: () => getAllCategoriesDao(p),
        };
      }),
    );
  };

  return {
    getCategories,
    getAllCategories,
    createCategory,
    removeCategory,
    updateCategory,
    getMultipleClientCategories,
  };
};
